import { sse } from '@/utils/request'
import { useChatStore, useSessionStore } from '@/store'
import { Models } from '@/typings'

export enum SseStatus {
  NoReady,
  Ready,
  Pendding,
}

export class SseClient {
  protected static evtSource: EventSource

  public static clientId: string
  public static readonly sourceUrl: string = this.getSourceUrl()
  // public static onOpen: ((e: Event) => void) | undefined
  // public static onTokenOld: ((convId: string, token: string) => void) | undefined
  // public static onMessage: ((message: any) => void) | undefined

  static get readyState() {
    return this.evtSource?.readyState
  }

  public static init_old() {
    if (!this.evtSource)
      this.init()

    return this
  }

  public static init() {
    if (!this.evtSource
      || this.evtSource.readyState === EventSource.CLOSED) {
      // useSessionStore().checkSessionStatus()
      this.clientId = this.getClientId()

      this.evtSource = sse({
        url: this.sourceUrl,
        params: new Map([[
          'client_id', this.clientId,
        ]]),
        onOpen: this.onOpen,
        onError: this.onError,
        onMessage: this.onUnknowMessage,
        onEvents: new Map([
          ['token', this.onToken],
          ['message', this.onMessage],
          ['notify', this.onNotify],
          ['config', this.onConfig],
          ['ping', this.onPing],
        ]),
      })
    }
  }

  public static close() {
    this.evtSource?.close()
  }

  private static onOpen(e: Event) {
    // console.log('sse pipe opened ...')
  }

  private static onError(e: Event) {
    // console.log('sse pipe error ...')
  }

  private static onUnknowMessage(e: MessageEvent) {
    // console.log('sse pipe unknow message :\n' + e)
  }

  private static onPing(e: MessageEvent) {
    // console.log('sse pipe recieved ping :' )
  }

  private static onToken(e: MessageEvent) {
    // console.log(`recieve new token data: ${JSON.stringify(e)}`)
    const token = e.data
    const ids = e.lastEventId.split('_')
    const convId = ids[0]
    const msgId = ids[1]
    useChatStore().addTokenFromServer(convId, msgId, token)
  }

  private static onMessage(e: MessageEvent) {
    // console.log(`recieve new message data:${JSON.stringify(e)}`)
    const msg = JSON.parse(e.data)
    const message = {
      id: msg.id,
      parentMsgId: msg.parent_msg_id,
      convId: msg.conv_id,
      chatId: msg.bot_id,
      clientId: msg.client_id,
      userId: msg.user_id,
      botId: msg.bot_id,
      msgType: Models.MessageType.AI,
      status: (msg.status === 'Error' ? Models.MessageStatus.Error : Models.MessageStatus.Done),
      content: msg.content,
      rawContent: msg.rawContent,
      error: (msg.status === 'Error' ? msg.error : null),
      createTime: new Date(msg.create_time),
      updateTime: new Date(msg.update_time),
    }

    useChatStore().addMessageFromServer(message)
  }

  private static onNotify(e: MessageEvent) {
    // console.log(`recieve new notify data:${JSON.stringify(e)}`)
  }

  private static onConfig(e: MessageEvent) {
    // console.log(`recieve new config data:${JSON.stringify(e)}`)
  }

  private static getClientId() {
    return useSessionStore().clientId
  }

  private static getSourceUrl() {
    const baseUrl = import.meta.env.VITE_GLOB_API_URL.replace('http:', location.protocol)
    return `${baseUrl}/sse`
  }
}
