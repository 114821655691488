import { defineStore } from 'pinia'
import { getLocalState, setLocalState, fetchBotsDbFromServer } from './helper'
import { Models, Dictionary } from '@/typings'
// import { useChatStore } from '../chat'

export const useFriendStore = defineStore('friend-store', {

    state: (): Models.FriendStore => {
        let state = getLocalState()
        return state
    },

    getters: {
        activeBot: (state)  => {
            return state.bots.find(bot => bot.id === state.activeBotId)
        },

        botDicts: (state) => {
            let botDicts: Dictionary<Models.Bot>  = {}
            state.bots.forEach(bot => { botDicts[bot.id] = bot})
            return botDicts
        },

        favedBots: (state) => {
            return state.bots
                        .filter(bot => bot.isFav)
                        .sort((a, b) => {
                            if(a.favTime && b.favTime)
                                return new Date(b.favTime).getTime()  - new Date(a.favTime).getTime() 
                            else
                                return 0
                        })
        },
    },

    actions: {
        setActiveBotId(botId: string){
            if(botId && this.hasBot(botId)){
                this.activeBotId = botId
                this.save2Local()
            }
        },
        hasBot(id: string){
            return this.bots.some(bot => bot.id === id)
        },
        getBotDetailById(id: string|null) {
            return this.bots.find(bot => bot.id === id)
        },

        favBot(bot: Models.Bot, isFav: boolean){
            bot.isFav = isFav
            bot.favTime = new Date()
            this.save2Local()
        },

        async syncUpBotsDB(){
            let bots =  await fetchBotsDbFromServer()
            if(bots && bots.length>0){
                if(this.bots.length==0){
                    bots.forEach(bot => {
                        bot.isFav = bot.defaultFav
                        bot.favTime = new Date()
                    })
                }
                else{
                    bots.forEach(bot => {
                        let localBot = this.bots.find(b => b.id===bot.id)
                        bot.isFav = localBot ? localBot.isFav : bot.defaultFav
                        bot.favTime = localBot ? localBot.favTime : new Date()
                    })
                }
                this.bots.length = 0
                bots.forEach(item => this.bots.push(item))
                if(!this.activeBotId || !this.activeBot){
                    this.activeBotId = this.activeBotId ??  bots[0].id
                }
                // useChatStore().syncUpBotDbWithFriendStore()
                this.save2Local()
            }
        },

        save2Local(){
            setLocalState(this.$state)
        }
    },
})

