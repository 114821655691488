import { Conversation } from "./chat"

export interface FriendStore {
    bots: Bot[]
    activeBotId: string | null 
    showSider: boolean
}

export interface Friend {
    id: string
    name: string
    header: string
}

export interface User extends Friend {
    status?: "Active" | "InActive" | "Forbidden"
    phone?: string
}

export interface Bot extends Friend {
    isFav?: boolean | null
    favTime?: Date
    defaultFav?: boolean
    mainCategory?: string
    category?: string
    slogon?: string
    greeting?: string
    detail?: {
        desc?: string
        ex?: {
            caption: string
            img: string
        }[]
    }
    exampleConv?: Conversation | undefined
}

export enum BotCategory{
    Assistant="assistant", 
    Text="text",
    Lanuage="language",
    Counselor="counselor",
    Code='code', 
    ProdTool="prod-tool",
    Media="media",
    DailyRole='daily-role',
    FilmRole='film-role',
    HistoryRole='history-role',
    EntTool='ent-tool'
}
