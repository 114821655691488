<script setup lang='ts'>
import { computed, ref } from 'vue'
import { NDropdown, NTooltip, NPopover, NAlert, useMessage } from 'naive-ui'
import { SvgIcon } from '@/components/common'
import { copyText } from '@/utils/format'
import { useIconRender } from '@/hooks/useIconRender'
// import { t } from '@/locales'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { Models } from '@/typings'
import AvatarComponent from './avatar.vue'
import TextComponent from './text.vue'

interface Props {
  dateTime?: string
  text?: string
  inversion?: boolean
  error?: string
  loading?: boolean
  message: Models.Message
}

interface Emit {
  (ev: 'regenerate', message: Models.Message): void
  (ev: 'delete', message: Models.Message): void
}

const props = defineProps<Props>()

const emit = defineEmits<Emit>()

const { isMobile } = useBasicLayout()

const nMessage = useMessage()

const { iconRender } = useIconRender()

const textRef = ref<HTMLElement>()

const asRawText = ref(props.inversion)

const messageRef = ref<HTMLElement>()

const options = computed(() => {
  const common = [
    // {
    //   // label: t('chat.copy'),
    //   label: '拷贝',
    //   key: 'copyText',
    //   icon: iconRender({ icon: 'ri:file-copy-2-line' }),
    // },
    {
      // label: t('common.delete'),
      label: '删除',
      key: 'delete',
      icon: iconRender({ icon: 'ri:delete-bin-line' }),
    },  
  ]

  if (!props.inversion) {
    common.push({
      // label: asRawText.value ? t('chat.preview') : t('chat.showRawText'),
      label: asRawText.value ? '预览' : '原文',
      key: 'toggleRenderType',
      icon: iconRender({ icon: asRawText.value ? 'ic:outline-code-off' : 'ic:outline-code' }),
    })
  }

  return common
})

function handleSelect(key: 'copyText' | 'delete' | 'toggleRenderType' | 'regenerate') {
  switch (key) {
    case 'copyText':
      copyText({ text: props.text ?? '' })
      nMessage.success("已拷贝", { duration: 1000 })
      return
    case 'toggleRenderType':
      asRawText.value = !asRawText.value
      return
    case 'delete':
      emit('delete', props.message)
    case 'regenerate':
      messageRef.value?.scrollIntoView()
      emit('regenerate', props.message)
  }
}

</script>

<template>
  <div
    ref="messageRef"
    class="flex w-full mb-6 overflow-hidden"
    :class="[{ 'flex-row-reverse': inversion }]"
  >
    <div
      class="flex items-center justify-center flex-shrink-0 h-8 overflow-hidden rounded-full basis-8"
      :class="[inversion ? 'ml-2' : 'mr-2']"
    >
      <AvatarComponent v-if="message" :message="message" />
    </div>
    <div class="overflow-hidden text-sm " :class="[inversion ? 'items-end' : 'items-start']">
      <!-- <p class="text-xs text-[#b4bbc4]" :class="[inversion ? 'text-right' : 'text-left']">
        {{ dateTime }}
      </p> -->
      <div
        class="flex items-end"
        :class="[inversion ? 'flex-row-reverse' : 'flex-row']"
      >
        <TextComponent
          ref="textRef"
          :inversion="inversion"
          :error="error"
          :text="text"
          :loading="loading"
          :as-raw-text="asRawText"
        />
        <div class="flex flex-col message-op-panel">
          <n-tooltip trigger="hover">
            <template #trigger>
              <button
                class="mb-2 transition text-neutral-300 hover:text-neutral-800 dark:hover:text-neutral-300"
                @click="handleSelect('copyText')"
              >
                <SvgIcon icon="ri:file-copy-2-line" />
                <!-- <SvgIcon icon="ri:restart-line " /> -->
                <!-- <SvgIcon icon="ri:delete-bin-line" /> -->
              </button>
            </template>
            复制
          </n-tooltip>
          <NDropdown
            :trigger="isMobile ? 'click' : 'hover'"
            :placement="!inversion ? 'right' : 'left'"
            :options="options"
            @select="handleSelect"
          >
            <button class="transition text-neutral-300 hover:text-neutral-800 dark:hover:text-neutral-200">
              <SvgIcon icon="ri:more-2-fill" />
            </button>
          </NDropdown>
        </div>
        <n-popover  v-if="error" trigger="hover" style="padding:0;">
          <template #trigger>
            <SvgIcon style="color:red;width: 20px;" icon="material-symbols:error-outline-rounded" />
          </template>
          <n-alert type="error" :bordered="false">
            {{ error }}
          </n-alert>
      </n-popover>
    
      </div>
    </div>
  </div>
</template>
<style scoped>
.message-op-panel{
  margin-left:  6px;
  margin-right: 6px;
}
</style>