/* eslint-disable no-console */
import type { GenericAbortSignal } from 'axios'
import { post } from '@/utils/request'
import type { Models } from '@/typings'

export function sendMessage<T = any>(
  params: {
    message: Models.Message
    options?: { conversationId?: string; parentMessageId?: string }
    signal?: GenericAbortSignal
    afterRequest?: () => void
  },
) {
  const msg = params.message
  return post<T>({
    url: '/message-send',
    data: {
      id: msg.id,
      parent_msg_id: msg.parentMsgId,
      conv_id: msg.convId,
      client_id: msg.clientId,
      user_id: msg.userId,
      chat_id: msg.chatId,
      bot_id: msg.chatId,
      user_type: msg.msgType,
      content: msg.content,
      replyMsgId: msg.replyMsgId,
    },
    signal: params.signal,
    afterRequest: params.afterRequest,
  })
}

export function fetchSmsCode<T>(phone: string) {
  return post<T>({
    url: '/get-sms-code',
    data: { phone },
  })
}

export function logInBySms(phone: string, smsCode: string) {
  return post<Models.User>({
    url: '/login-sms',
    data: { phone, sms_code: smsCode },
  })
}

export function activateUser(inviteCode: string) {
  return post<Models.User>({
    url: '/activate-user',
    data: { invite_code: inviteCode },
  })
}

// export function logIn<T>(password: string) {
//   return post<T>({
//     url: '/login',
//     data: { password },
//   })
// }

export function fetchBots() {
  return post<Models.Bot[]>({
    url: '/bots',
  })
}

export function fetchChatConfig<T = any>() {
  return post<T>({
    url: '/config',
  })
}

export function fetchSession<T>() {
  return post<T>({
    url: '/session',
  })
}
