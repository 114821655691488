import { v4 as uuidv4 } from 'uuid';

export function useWindowManager() {

    let activeWindowId: string | null = null;

    const setSingleWindowMode = () => {
        activeWindowId = uuidv4();
        localStorage.setItem('active-window-id', activeWindowId)
        window.addEventListener("storage", function (e) {
            if (e.key === 'active-window-id' && !!e.newValue ) {
                location.href = "/inactive-window.html"
            }
        })
    }

    return {
        setSingleWindowMode
    }
}