<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    
})
</script>
<template>
    <div style="line-height: 40px; text-align: center;">
        <img src="/logo/logo-banner-640.jpeg" class="logo-banner"/>
        <p>AI Friends, 你的AI朋友圈！</p>
        <p>在这里，<br/>结识一群身怀绝技的AI朋友。<br/>帮助你提升工作效率，<br/>完成一些你完成不了的任务，<br/>或是只是聊聊天打发下时间。</p>
        <p>联系我们：<a href="mailto:ai-friends@outlook.com" style="color:blue;">ai.friends@outlook.com</a></p>
    </div>
</template>

<style scoped>
    .logo-banner{
        margin: 0 auto 12px auto;
    }
    img{
        width: 100%;
    }
</style>
