<script lang="ts">
import { h, Component } from 'vue'
import { NLayout, NLayoutSider, NLayoutContent, NLayoutFooter, NMenu, NIcon, NAvatar } from 'naive-ui'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import type { MenuOption } from 'naive-ui'
import { RouterLink, RouteLocationNormalizedLoaded as Route } from 'vue-router'
import ActivePanel from './activePanel.vue'
import LoginPanel from './loginPanel.vue'
import { useSessionStore, useFriendStore, useAppStore } from '@/store'
import { SseClient } from '@/api'
import { Models } from '@/typings'
import {
  Home as HomeIcon,
  ChatboxEllipses as ChatIcon,
  People as FriendIcon,
  Search as TimelineIcon,
  Settings as SettingsIcon,
  PersonCircle as UserIcon
} from '@vicons/ionicons5'

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}


const menuOptions: MenuOption[] = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Models.PageName.ChatPage,
          }
        },
        { default: () => '聊天' }
      ),
    key: Models.PageName.ChatPage,
    icon: renderIcon(ChatIcon)
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Models.PageName.FriendPage,
          }
        },
        { default: () => '好友' }
      ),
    key: Models.PageName.FriendPage,
    icon: renderIcon(FriendIcon)
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Models.PageName.FindPage,
          }
        },
        { default: () => '发现' }
      ),
    key: Models.PageName.FindPage,
    icon: renderIcon(TimelineIcon)
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Models.PageName.SettingsPage,
          }
        },
        { default: () => '设置' }
      ),
    key: Models.PageName.SettingsPage,
    icon: renderIcon(SettingsIcon)
  },
]

export default {
  components: {
    NLayout, NLayoutSider, NLayoutContent, NLayoutFooter, NMenu, NIcon, NAvatar,
    ActivePanel, LoginPanel
  },
  setup() {
    // useSessionStore().checkSessionStatus()
    return {
      HomeIcon, ChatIcon, FriendIcon, SettingsIcon, UserIcon, TimelineIcon, NLayoutFooter,
      menuOptions
    }
  },
  data() {
    return {
      activeMenuKey: '',
      session: useSessionStore(),
      friendStore: useFriendStore(),
      appStore: useAppStore(),
      isMobile: useBasicLayout().isMobile,
      mShowNaviBar: false
    }
  },
  computed: {
    showNaviBar() {
      return this.isMobile && this.mShowNaviBar
    }
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.initAppConfig()
    })
  },
  mounted() {
    this.loadStateFromRoute(this.$route)
    this.session.isLoggedIn && this.initUserConfig()
  },
  watch: {
    '$route': function (to, from) {
      // console.log(`$route changed : ${from.name.toString()} -> ${to.name.toString()}`)
      to !== from && this.loadStateFromRoute(to, from)
    },
    'session.isLoggedIn': function (to, from) {
      if(to){
        this.initUserConfig()
      }
      else{
        SseClient.close()
      }
    }
  },
  methods: {
    loadStateFromRoute(to: Route, from?: Route) {
      // 根据路由元数据决定是否显示底部导航栏
      this.mShowNaviBar = !!to.meta.showBottomNaviBar

      // 根据路由数据决定当前激活的导航菜单项
      const toPage = to.matched[1]?.name?.toString()
      const fromPage = from?.matched[1]?.name?.toString()
      // console.log(`route name changed : ${ fromPage } -> ${ toPage }`)
      if (toPage && toPage !== fromPage) {
        this.changeActiveMenu(toPage)
      }

    },
    changeActiveMenu(menuKey: string) {
      if (this.activeMenuKey != menuKey
        && this.menuOptions.some(menu => menu.key === menuKey)) {
        this.activeMenuKey = menuKey
      }
    },
    getMobileClass() {
      if (this.isMobile)
        return ['rounded-none', 'shadow-none']
      return ['border', 'rounded-md', 'shadow-md', 'dark:border-neutral-800']
    },
    getContainerClass() {
      return [
        'h-full',
        { 'pl-[260px]': !this.isMobile },
        // { 'pl-[260px]': !this.isMobile && !collapsed.value },
      ]
    },
    initAppConfig() {
      // 同步系统级全局配置
    },
    initUserConfig() {      
      useFriendStore().syncUpBotsDB()
      SseClient.init()
    }
  }
}
</script>
<template>
  <div class="h-full dark:bg-[#24272e] transition-all" :class="[isMobile ? 'p-0' : 'p-4']">
    <div class="h-full overflow-hidden" :class="getMobileClass">
      <template v-if="session.isLoggedIn && session.isActive && friendStore.bots.length">
        <n-layout id="page-root" has-sider>
          <n-layout-sider v-if="!isMobile" id="navi-bar-root-v" width="66" content-style="padding: 0px;" bordered>
            <div id="navi-bar-header">
              <router-link to="/settings/about">
                <img src="/logo/logo-320.jpeg" class="logo" alt="AI Friends" />
              </router-link>
            </div>
            <n-menu id="navi-menus" collapsed :collapsed-width="66" :collapsed-icon-size="24" :options="menuOptions"
              v-model:value="activeMenuKey" />
            <div id="navi-bar-footer">
              <RouterLink :to="'/settings/profile'">
                <NAvatar :src="session.user?.header" round />
              </RouterLink>
            </div>
          </n-layout-sider>
          <n-layout-content id="main-area" :class="{ 'show-h-navibar': showNaviBar }">
            <RouterView ref="rootRouterView">
            </RouterView>
          </n-layout-content>
        </n-layout>
        <n-layout-footer v-if="showNaviBar" id="navi-bar-root-h" position="absolute">
          <n-menu class="navi-menus-h" :collapsed-width="66" :collapsed-icon-size="24" :options="menuOptions"
            v-model:value="activeMenuKey" mode="horizontal" />
        </n-layout-footer>
      </template>

      <LoginPanel v-if="!session.isLoggedIn" />
      <ActivePanel v-if="session.isLoggedIn && !session.isActive" />

    </div>
  </div>
</template>

<style scoped>
#page-root {
  height: 100%;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid lightgray;
}

#navi-bar-root-v {
  background-color: lightgray;
}

#navi-bar-root-h {
  background-color: lightgray;
  z-index: 3;
  bottom: 0;
  text-align: center;
}

#navi-bar-root-h .n-menu {
  margin: 6px;
  padding: 0;
  line-height: 1;
}

#navi-bar-header {
  margin: 10px 10px 6px 10px;
}

#navi-bar-header .logo {
  border-radius: 6px;
}

#navi-bar-footer {
  position: absolute;
  bottom: 0;
  margin: 16px;
}

#main-area {}

#main-area.show-h-navibar {
  margin-bottom: 54px
}</style>../../hooks/useWindowManager