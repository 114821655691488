import { ss } from '@/utils/storage'

const LOCAL_NAME = 'chatchatStorage'

export function defaultState(): ChatChat.ChatState {
  const uuid = 1002
  return {
    active: uuid,
    usingContext: true,
    history: [{ uuid, title: 'New Chat', isEdit: false }],
    chat: [{ uuid, data: [] }],
  }
}

export function getLocalState(): ChatChat.ChatState {
  const localState = ss.get(LOCAL_NAME)
  return { ...defaultState(), ...localState }
}

export function setLocalState(state: ChatChat.ChatState) {
  ss.set(LOCAL_NAME, state)
}