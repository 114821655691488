export class CookieUtil {
    /**
     * 获取指定名称的Cookie值
     * @param name Cookie名称
     */
    static getCookie(name: string): string | null {
      const cookieStr = document.cookie;
      const cookieArr = cookieStr.split('; ');
      for (let i = 0; i < cookieArr.length; i++) {
        const arr = cookieArr[i].split('=');
        if (arr[0] === name) {
          return decodeURIComponent(arr[1]);
        }
      }
      return null;
    }
  
    /**
     * 设置Cookie值
     * @param name Cookie名称
     * @param value Cookie值
     * @param options 可选配置项
     */
    static setCookie(name: string, value: string, options?: CookieOptions): void {
      let cookieStr = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
      if (options) {
        if (options.expires) {
          const expires = options.expires instanceof Date ? options.expires.toUTCString() : options.expires;
          cookieStr += `; expires=${expires}`;
        }
        if (options.path) {
          cookieStr += `; path=${options.path}`;
        }
        if (options.domain) {
          cookieStr += `; domain=${options.domain}`;
        }
        if (options.secure) {
          cookieStr += '; secure';
        }
      }
      document.cookie = cookieStr;
    }
  
    /**
     * 删除指定名称的Cookie值
     * @param name Cookie名称
     * @param options 可选配置项
     */
    static deleteCookie(name: string, options?: CookieOptions): void {
      this.setCookie(name, '', {
        ...options,
        expires: new Date(0)
      });
    }
  }
  
  interface CookieOptions {
    expires?: Date | string;
    path?: string;
    domain?: string;
    secure?: boolean;
  }
  