<script lang="ts">
import { useMessage, useDialog, NButton, NAvatar } from 'naive-ui'
import { useSessionStore } from '@/store';
import { useBasicLayout } from '@/hooks/useBasicLayout';

export default{
    components:{ useMessage, useDialog, NButton, NAvatar },
    data(){
        return {
            session: useSessionStore(),
            dialog : useDialog(),
            isMobile: useBasicLayout().isMobile
        }
    },
    methods:{
        logOut(){
            this.dialog.warning({
                title: '退出登录',
                content: '退出登录将退出当前登录账号，并清空所有本地数据。确定退出？',
                positiveText: '确定',
                negativeText: '取消',
                closable: false,
                onPositiveClick: () => {
                    this.session.logOut()
                },
            })
        }
    }
}
</script>

<template>
    <div id="profile-card" v-if="session.user">
        <NAvatar round class="avatar"  :size="200" :src="session.user.header"/>
        <div id="name">{{ session.user.phone }}</div>
        <NButton  @click="logOut()"  type="primary">退出登录</NButton>
    </div>
</template>

<style scoped>
#profile-card{
    min-width: 320px;
    max-width: 640px;
    margin: 0 auto; 
    padding: 24px;
    background-color:transparent ;
    text-align: center;
}
#profile-card .avatar{
    transition: transform 0.3s ease-in-out;
}
#profile-card .avatar:hover{
    transform: scaleX(-1);
}
#profile-card #name{
    font: 30px bold;
    margin: 12px;
}
#profile-card button{
    margin: 12px;
}
</style>