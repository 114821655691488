<script lang="ts" setup>
import { computed } from 'vue'
import { NAvatar } from 'naive-ui'
import defaultAvatar from '@/assets/avatar.jpg'
import { Models } from '@/typings'
import { useSessionStore , useFriendStore } from '@/store'

interface Props {
  // avartar: string | null | undefined
  message: Models.Message
}
const props = defineProps<Props>()


const sesseion =  useSessionStore()
const friendStore = useFriendStore()
let avartar = computed(() => {
  let result = undefined
  if(props.message) {
    switch(props.message.msgType){
      case Models.MessageType.User:
        result = sesseion.user?.header
        break
      case Models.MessageType.AI:
        result = friendStore.botDicts[props.message.chatId]?.header 
        break
      default :
        result = undefined
        break
    }
  }
  
  return result
})

</script>

<template>
    <RouterLink v-if="message.msgType===Models.MessageType.AI" :to="('/friend/'+message.chatId)">
      <NAvatar :src="avartar" :fallback-src="defaultAvatar"/>
    </RouterLink>
    <NAvatar v-else :src="avartar" :fallback-src="defaultAvatar"/> 
</template> 