// import { ss } from '@/utils/storage'
import { CookieUtil, DateTimeUtil } from '@/utils'
import { ss } from '@/utils/storage'
import { SessionState } from '../session/session'
import { v4 as uuid4 } from 'uuid'


const COOKIE_NAME = 'AccessToken'

export function getToken() {
  return CookieUtil.getCookie(COOKIE_NAME)
}

export function hasToken(){
  return Boolean(getToken())
}

export function setToken(token: string) {
  return CookieUtil.setCookie(COOKIE_NAME, token, { 
    expires: DateTimeUtil.getTimeAfterDays(1)
  })
}

export function removeToken() {
  return CookieUtil.deleteCookie(COOKIE_NAME)
}


const LOCAL_NAME = 'session-store'

function defaultState(): SessionState {
  return {
    isLoggedIn: false,
    clientId: uuid4(),
    user: null
  }
}

export function getLocalState(): SessionState {
  const localState = ss.get(LOCAL_NAME)
  if(localState){
    localState['isLoggedIn'] = hasToken()
    return localState
  }
  else{
    return defaultState()
  }
}

export function setLocalState(state: SessionState) {
  ss.set(LOCAL_NAME, state)
}

export function clearAllLocalStorage(){
  ss.clear()
}


// const LOCAL_NAME = 'ACCESS_TOKEN'

// export function getToken() {
//   return ss.get(LOCAL_NAME)
// }

// export function setToken(token: string) {
//   return ss.set(LOCAL_NAME, token)
// }

// export function removeToken() {
//   return ss.remove(LOCAL_NAME)
// }
