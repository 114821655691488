<script lang="ts">
import { defineComponent, Component, h } from 'vue'
// import { SvgIcon } from '@/components/common'
import { UserProfile as ProfileIcon } from '@vicons/carbon'
import {PersonFeedback20Regular as FeebackIcon,
        ContactCardLink16Regular as AboutMeIcon } from '@vicons/fluent'
import { NSpace, NLayout, NLayoutSider, NLayoutHeader, NLayoutContent, NMenu, NIcon, NButton, MenuOption } from 'naive-ui'
import { RouterLink} from 'vue-router'
import { Dictionary } from '@/typings/customType'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { SvgIcon } from '@/components/common'
import { useAppStore } from '@/store';
import { Models } from '@/typings'
import { routerUtils } from '@/router/routerUtils'

function renderIcon (icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const menuDicts: Dictionary<string> = {
    'ProfileVue': '账号管理',
    'FeedbackVue': '意见反馈',
    'AboutVue': '关于我们',
}

const menuOptions: MenuOption[] = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'ProfileVue'
          }
        },
        { default: () => menuDicts['ProfileVue'] }
      ),
    key: 'ProfileVue',
    icon: renderIcon(ProfileIcon)
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'FeedbackVue'
          }
        },
        { default: () => menuDicts['FeedbackVue'] }
      ),
    key: 'FeedbackVue',
    icon: renderIcon(FeebackIcon),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'AboutVue'
          }
        },
        { default: () => menuDicts['AboutVue'] }
      ),
    key: 'AboutVue',
    icon: renderIcon(AboutMeIcon),
  },
]

export default defineComponent({
    components:{
        NSpace, NLayout, NLayoutSider, NLayoutHeader, NLayoutContent, NMenu, NIcon, NButton,
        SvgIcon
    },
    setup(){
      return {
        menuOptions,
        menuDicts
      }
    },
    data(){
        return {
            appStore: useAppStore(),
            isMobile: useBasicLayout().isMobile,
        }
    },
    computed: {
      childPage(){
        const childPage = this.$route.name?.toString()
        return  (childPage && this.menuDicts.hasOwnProperty(childPage)) ? childPage : ""
      },
      hasChild(){
        return !!this.childPage 
      },
      activeMenuKey(){
        return this.childPage
      },
      showSider(){
        return !this.isMobile || (this.isMobile && !this.hasChild)
      },
      needRedirect(){
        // 此处处理离开当前页面的情况
        if(this.$route.matched[1]?.name?.toString() != Models.PageName.SettingsPage){
          console.log('leaving settings page ... ')
          return false
        }

        return !this.isMobile && !this.hasChild 
      }
    },
    mounted(){
      console.log('settings page mouted: ', this.$route.name)
      this.needRedirect && this.redirectToDefaultChild()
    },
    watch:{
      needRedirect(to, from){
        console.log(`${ Models.PageName.SettingsPage } needRedirect changed : ${from} -> ${to} `)

        if(to){
          this.redirectToDefaultChild()
        }
      }
    },
    methods: {
      redirectToDefaultChild(){
          // PC端默认载入Profile页
          this.$router.replace({name : 'ProfileVue'})
      },
      handleBack(){
          if(routerUtils.canGoBack()){
              this.$router.back()    
          }
          else{
              this.$router.replace('/settings')
          }
      }
    }
})
</script>

<template>
    <NLayout has-sider>
        <NLayoutSider bordered :width="isMobile ? '100%': 320"  :collapsed-width="0" :collapsed="!showSider">
            <NLayoutHeader class="page-header">
                设置
            </NLayoutHeader>
            <NLayoutContent class="sider-bar-content">
                <NMenu :options="menuOptions" :value="activeMenuKey" />
            </NLayoutContent>
        </NLayoutSider>
        <NLayoutContent>
            <NLayout>
                <NLayoutHeader class="page-header flex items-center justify-between">
                  <div class="flex items-center" v-if="isMobile">
                    <button  class="flex items-center justify-center w-11 h-11" @click="handleBack" >
                      <SvgIcon  class="text-2xl" icon="ic:baseline-arrow-back-ios" />
                    </button>
                  </div>
                  <h1 class="flex-1 px-4 pr-6 overflow-hidden cursor-pointer select-none text-ellipsis whitespace-nowrap" >
                    {{ menuDicts[activeMenuKey] }}
                  </h1>

                </NLayoutHeader>
                <NLayoutContent class="page-content">
                    <RouterView/>
                </NLayoutContent>
            </NLayout>
        </NLayoutContent>
    </NLayout>
</template>

<style>

</style>