<script lang="ts">
import { nextTick, ref } from 'vue'
import { HoverButton, SvgIcon } from '@/components/common'
import { Models } from '@/typings'
import { PropType } from 'vue'
import { NModal, NCheckbox,useMessage } from 'naive-ui'
import { useChatStore } from '@/store'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { routerUtils } from '@/router/routerUtils'

export default{
  components:{
    NModal, NCheckbox, useMessage,
    HoverButton, SvgIcon
  },
  props:{
    chat : {
      type: Object as PropType<Models.Chat | null>,
    },
    title: {
      type: String,
      default: 'Hello'
    }
  },
  setup(){
    return {
      nmsg: useMessage()
    }
  },
  data(){
    return {
      chatStore: useChatStore(),
      showClearModal: ref(false),
      closeChatWindowChecked: ref(false),
      isMobile : useBasicLayout().isMobile
    }
  },
  emits:['export', 'toggleUsingContext', 'clear','stop-recieve'],
  methods:{
    handleBack() {
      if(routerUtils.canGoBack()){
          this.$router.back()    
      }
      else{
          this.$router.replace('/chat')
      }
    },
    onScrollToTop() {
      const scrollRef = document.querySelector('#scrollRef')
      if (scrollRef)
        nextTick(() => scrollRef.scrollTop = 0)
    },
    handleExport() {
      this.$emit('export')
    },
    openCLearModal(){
      this.closeChatWindowChecked = false
      this.showClearModal = true
    },
    handleClear() {
      if(this.chat){
        if(this.chat.busyMode.isBusy){
          this.$emit('stop-recieve')
        }

        if(this.closeChatWindowChecked){
          if(this.chatStore.chats.length<=1){
            this.nmsg.error('就剩这一个了，别删了！')
            return
          }

          this.chatStore.removeChat(this.chat)
          this.$router.replace('/chat')
        }
        else{
          this.chatStore.clearChat(this.chat)
        }
      }
    }
  }
}

</script>

<template>
  <header 
    class="sticky top-0 left-0 right-0 z-30 border-b dark:border-neutral-800 bg-white/80 dark:bg-black/20 backdrop-blur"
  >
    <div class="relative flex items-center justify-between min-w-0 overflow-hidden h-14">
      <div class="flex items-center" v-if="isMobile">
        <button
          class="flex items-center justify-center w-11 h-11"
          @click="handleBack"
        >
          <SvgIcon  class="text-2xl" icon="ic:baseline-arrow-back-ios" />
        </button>
      </div>
      <h1
        class="flex-1 px-4 pr-6 overflow-hidden cursor-pointer select-none text-ellipsis whitespace-nowrap"
        @dblclick="onScrollToTop"
      >
      与 <b>{{ title }}</b> 的聊天 @ AiFriends.Chat
      </h1>
      <div class="flex items-center space-x-2" >
        <HoverButton  @click="openCLearModal">
          <span class="text-xl text-[#4f555e] dark:text-white">
            <SvgIcon icon="ant-design:clear-outlined" />
            <!-- <SvgIcon icon="ri:delete-bin-line" /> -->
          </span>
        </HoverButton>
        <HoverButton @click="handleExport">
          <span class="text-xl text-[#4f555e] dark:text-white">
            <SvgIcon icon="ic:outline-ios-share" />
          </span>
        </HoverButton>
      </div>
    </div>
  </header>
  <n-modal
    v-model:show="showClearModal"
    preset="dialog"
    type="warning"
    title="清空窗口"
    positive-text="确认"
    negative-text="取消"
    @positive-click="handleClear"
    @negative-click="showClearModal=false"
  >
    <div class="modal-content">
      <p >确定要清空当前聊天窗口所有内容?</p>
      <n-checkbox label="同时关闭当前聊天窗口"
        v-model:checked="closeChatWindowChecked"   />
    </div>
  </n-modal>
</template>

<style scoped>
/* header{
    height: 60px;
    border-bottom: 2px gray;
    line-height: 60px;
    vertical-align: middle;
    text-align: left;
    font: 16px bold;
    padding: 12px;
  } */
  header{
    padding-right: 16px;
  }
  /* .modal-content { */
    /* text-align: center; */
    /* line-height: 40px; */
  /* } */
  </style>
