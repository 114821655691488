<script lang="ts">
import { defineComponent } from 'vue'
import wechatQrCode from '@/assets/wechat-qrcode.jpg'

export default defineComponent({
    setup(){
        return {
            wechatQrCode
        }
    }
})
</script>
<template>
    <div style="line-height: 40px;" class="page-content">
        <p>使用中有任何问题，<br/>包括功能建议、BUG报告等，<br/>都欢迎随时通过以下公众号直接反馈给我们。</p>
        <p>请尽量包含出现问题的页面截图，<br/>以便于我们尽快解决您反馈的问题。</p>
        <img :src="wechatQrCode" />
    </div>
</template>

<style scoped>
    .page-content{
        min-width: 320px;
        max-width: 640px;
        margin: 0 auto; 
        padding: 24px;
        background-color:transparent ;
        text-align: center;
    }

    .page-content img {
        max-width: 400px;
        margin: 12px auto;
        text-align: center;
    }
    
    img{
        width: 100%;
    }
</style>