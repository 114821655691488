import { defineStore } from 'pinia'
import { Models } from '@/typings'
import { clearAllLocalStorage as clearLocalStorage, getLocalState, hasToken, removeToken, setLocalState } from '../auth/helper'
import { store, useChatStore, useFriendStore } from '@/store'


export interface SessionState {
  isLoggedIn: boolean,
  clientId: string,
  user?: Models.User | null
}

export const useSessionStore = defineStore('session-store', {

  state: (): SessionState =>  getLocalState(),

  getters: {
    isActive(): Boolean {
      return this.user?.status === 'Active'
    }
  },

  actions: {
    updateSession(user: Models.User){
      // 切换用户的情况下,清空老用户数据
      if( this.user?.id && this.user?.id !== user.id){
          this.clearUserData()
      }

      this.user = user
      this.isLoggedIn = true
      setLocalState(this.$state)
    },
    
    checkSessionStatus(){
      const isLoggedIn = hasToken()
      if(this.isLoggedIn !== isLoggedIn){
        this.isLoggedIn = isLoggedIn
        setLocalState(this.$state)
      }
      return isLoggedIn
    },

    logOut(){
      removeToken()
      clearLocalStorage()
      location.replace('/')
    },

    clearUserData(){
      clearLocalStorage()
      useChatStore().$reset()
      useFriendStore().$reset()
    },
  },
})

export function userSessionStoreWithout(){
  return useSessionStore(store)
}
