import { ss } from '@/utils/storage'
import { Models } from '@/typings'
import { HttpClient } from '@/api'

const LOCAL_NAME = 'friend-store'

export function defaultState(): Models.FriendStore {
  return {
      bots: [],
      activeBotId: null,
      showSider: true,
  }
}

export function getLocalState(): Models.FriendStore{
  const localState = ss.get(LOCAL_NAME)
  
  //冷启动时从服务器加载一次数据
  // if(!localState){
  //   setTimeout(() => {
  //     updateBotsDbFromServer()
  //   }, 0)
  // }

  return { ...defaultState(), ...localState}
}

export function setLocalState(state: Models.FriendStore){
  ss.set(LOCAL_NAME, state)
}

export async function fetchBotsDbFromServer(){
  try{
    const bots = await HttpClient.fetchBots() as Models.Bot[]
    console.log('update bots db success ...')
    return bots
  }
  catch(e){
    console.log('update bots db fail ...')
    console.log(e)
    return null
  }
}

