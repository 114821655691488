<script lang='ts'>
import { NButton, NInput, NModal, useMessage } from 'naive-ui'
import { ApiError, ApiStatusCode, HttpClient } from '@/api'
import { useSessionStore } from '@/store'
import { Models, RegExpRules } from '@/typings'

export default {
  components: {
    NButton, NInput, NModal, useMessage
  },
  setup(){

  },
  data(){
    return {
      session: useSessionStore(),
      nmsg: useMessage(),
      loading: false,
      activeCode: '',
    }
  },
  computed:{
    activeButtonEnable(){
      return RegExpRules.InviteCode.test(this.activeCode.trim()) && !this.loading
    }
  },
  methods:{
    async handleVerify() {
      const inviteCode = this.activeCode.trim()
      if (!inviteCode) return

      try {
        this.loading = true
        const user = await HttpClient.activateUser(inviteCode) as Models.User
        this.session.updateSession(user)
        this.nmsg.success('激活成功')
        // window.location.reload()
      }
      catch (error: any) {
        if(error instanceof ApiError){
          switch(error.errorCode){
            case ApiStatusCode.Error_InviteCode_Unavailable:{
              const e = error as ApiError<string>
              const msgDict = {
                'Exhausted': "此邀请码激活次数已用尽",
                'Expired' : "邀请码已过期",
                'Disabled' : '邀请码已废弃',
              }
              const key = e.detail as 'Exhausted'|'Expired'|'Disabled'
              const msg = msgDict[key]
              this.nmsg.error(msg)
              break
            }
            case ApiStatusCode.Error_User_NotFound:
            case ApiStatusCode.Error_User_Forbidden:
            case ApiStatusCode.Error_InviteCode_NotFound:
            default:
              this.nmsg.error(error.message)
          }
        }
        else{
          this.nmsg.error("激活失败，请稍后重试")
        }
      }
      finally {
        this.loading = false
      }
    },

  }
}


</script>

<template>
  <NModal show style="width: 90%; max-width: 640px">
    <div class="p-10 bg-white rounded dark:bg-slate-800">
      <div class="space-y-4">
        <header class="space-y-2">
          <img src="/logo/logo-banner-640.jpeg" alt="AI Friends" class="logo-banner">
          <h2 class="text-2xl font-bold text-center text-slate-800 dark:text-neutral-200">
            激活账号
          </h2>
          <p class="text-base text-center text-slate-500 dark:text-slate-500">
            <!-- {{ $t('common.unauthorizedTips') }} -->
          </p>
        </header>
        <NInput type="text" placeholder="请输入邀请码" size="large"
          v-model:value="activeCode"  @keydown.enter="handleVerify" />
        <NButton
          block type="primary" size="large"
          :disabled="!activeButtonEnable"
          :loading="loading"
          @click="handleVerify"
        >
          激活
        </NButton>
      </div>
    </div>
  </NModal>
</template>

<style scoped>
.logo-banner {
  margin-bottom: 36px;
}
</style>
