<script lang="ts">
import { NSpace, NButton, NPopover, useDialog, useMessage  } from 'naive-ui'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { v4 as uuid4 } from 'uuid'
import { HoverButton, SvgIcon } from '@/components/common'
import { Models } from '@/typings'
import { useChatStore, useFriendStore, useSessionStore } from '@/store'
import { ApiError, HttpClient } from '@/api'
import { useCopyCode } from '../hooks/useCopyCode'
import { useScroll } from '../hooks/useScroll'
import html2canvas from 'html2canvas'

import { Header, Footer, Message} from './components'
import { DateTimeUtil } from '@/utils/datetime'
import { ref } from 'vue'
import { MessageStatus, MessageType } from '@/typings/models'


export default{
  components:{
      NSpace, NPopover, NButton,
      HoverButton, SvgIcon,
      HeaderComponent: Header, FooterComponent: Footer,
      MessageComponent: Message
  },
  setup(){
    useCopyCode()
    const { scrollRef, scrollToBottom, scrollToBottomIfAtBottom } = useScroll()
    return { scrollRef, scrollToBottom, scrollToBottomIfAtBottom,
            ndialog : useDialog(),
            nmsg : useMessage(),
            Models, 
    }
  },
  data() {
      return {
            isMobile : useBasicLayout().isMobile,
            chatStore: useChatStore(),
            session: useSessionStore(),
            // chat: useChatStore().activeChat,
            botDicts: useFriendStore().botDicts,
            scrollHeight: ref(0),
      }
  },

  computed: {
  },

  mounted(){
    this.scrollToBottom()
  },

  watch:{
    'chatStore.activeChatId'(newValue, oldValue){
        this.scrollToBottom()
    },
    'chatStore.activeMessage.content'(newValue, oldValue){
      if( newValue?.length > oldValue?.length 
          && this.chatStore.activeChat?.busyMode?.isBusy){
        this.scrollToBottomIfAtBottom()
      }
    }
  },

  methods: {

      createNewMessageAndSave(input:string){

        // 忽略登录状态异常情况
        if(!this.session.user)  return

        // 忽略没有激活聊天窗口而发送消息的异常情况
        const chat = this.chatStore.activeChat
        if(!chat) return

        // 构造Message
        const message = {
          id : uuid4(),
          parentMsgId: '',
          convId: '',
          chatId: chat.botId,
          clientId: this.session.clientId,
          userId: this.session.user.id,
          msgType: Models.MessageType.User,
          status: Models.MessageStatus.New,
          content: input,
          createTime:  new Date(),
          updateTime:  new Date(),
        }

        // 寻找当前激活聊天窗口的激活会话
        let conv = this.chatStore.activeConv
        if( !chat.isChatMode || !conv || conv.id==='example'){
          // 没有任何会话的新窗口发送消息时自动创建一个新会话
          const convId = uuid4()
          message.convId = convId
          conv = {
            id: convId,
            chatId: chat.id,
            userId: this.session.user.id,
            botId: chat.botId,
            messages: [ ] as Models.Message[],
            createTime: new Date(),
            updateTime: new Date(),
          }
          chat.conversations.push(conv)
        }

        message.convId = conv.id
        if(conv.messages.length > 0) {
          message.parentMsgId = conv.messages[conv.messages.length - 1].id
        }

        conv.messages.push(message)
        chat.summary = conv.summary = message.content.slice(0,40)
        chat.updateTime = conv.updateTime = new Date()
        // chat.newMsgCount = isNumber(chat.newMsgCount) ? chat.newMsgCount + 1 : 0

        this.chatStore.save2Local()
        return message
      },

      async sendMessageToServer(message: Models.Message){
        const chat = this.chatStore.activeChat
        const conversation = this.chatStore.activeConv
        if(!chat || !conversation) return
        
        chat.busyMode = { isBusy: true, type: 'Sendding', info: "信息发送中..." }
        chat.updateTime = new Date()
        message.status = Models.MessageStatus.Pendding
        message.replyMsgId = uuid4()
        // this.chatStore.save2Local()

        try{
          await HttpClient.sendMessage<ChatChat.ConversationResponse>({
            message: message,
            options: { 
              conversationId: message.convId,
              parentMessageId: message.parentMsgId
            },
          })

          message.status = Models.MessageStatus.Done
          chat.busyMode = { isBusy: true, type: 'Waitting', info: "信息处理中..." }
          let replyMsg = {
            id: message.replyMsgId,
            parentMsgId: message.id,
            convId: message.convId,
            chatId: message.chatId,
            clientId: message.clientId,
            userId: message.userId,
            msgType: Models.MessageType.AI,
            status: Models.MessageStatus.New,
            content: '',
            createTime:  new Date(),
            updateTime:  new Date(),
          } as Models.Message
          conversation.messages.push(replyMsg)
          chat.updateTime = new Date()
          this.chatStore.save2Local()

          // 设置60秒超时，如果超时仍未收到完整回复，则自动解除接收状态，防止因网络问题导致状态卡死，影响后续使用
          // setTimeout( this.refreshChatStatus, 60000)

        }
        catch(e) {
          message.error = {
            type : "NetworkError",
            info: "网络错误，发送失败"
          }
          if(e instanceof ApiError){
            message.error = {
              type: "NetworkError",
              info: e.message
            }
          }
          this.ndialog.error({
            title: '出错了',
            content: message.error.info
          })

          message.status = Models.MessageStatus.Error
          message.updateTime = new Date()
          chat.busyMode = { isBusy: false }
          chat.updateTime = new Date()
          this.chatStore.save2Local()
        }
      },

      stopRecieveToken(){
        let chat = this.chatStore.activeChat
        // let conv = this.chatStore.activeConv
        let message = this.chatStore.activeMessage

        if(chat && message && message.msgType === MessageType.AI
          && ![MessageStatus.Done, MessageStatus.Error].includes(message.status)){
          message.status = MessageStatus.Stop
          message.content += '......|中止逐字接收|'
          message.updateTime = new Date()
          chat.busyMode = { isBusy: false }
          this.chatStore.save2Local()
        }
        
      },

      refreshChatStatus() {
        let chat = this.chatStore.activeChat
        // let conv = this.chatStore.activeConv
        let message = this.chatStore.activeMessage
        if( message && chat?.busyMode?.isBusy 
            && DateTimeUtil.getTimeDiff(chat?.updateTime) > 60000){

          if(chat.busyMode.type == "Sendding" 
            && message?.msgType == Models.MessageType.User
            && message?.status !== Models.MessageStatus.Done){

              message.error = {
                type: "NetworkError",
                info: "网络错误，信息发送失败"
              }
          }
          else if(chat.busyMode.type == "Waitting" 
            && message?.msgType == Models.MessageType.AI
            && message?.status !== Models.MessageStatus.New){
              message.error = {
                type: "NetworkError",
                info: "网络错误，信息接收失败"
              }
          }
          else if(chat.busyMode.type == "Recieving"
            && message?.msgType == Models.MessageType.AI
            && message?.status !== Models.MessageStatus.Pendding){
            message.error = {
              type: "MsgBroken",
              info: "网络错误，信息接收不完整"
            }
          }
          else{
            message.error = {
              type: "Unknow",
              info: "未知错误"
            }
          }

          message.status = Models.MessageStatus.Error
          message.updateTime = new Date()
          chat.busyMode = { isBusy: false }
          chat.updateTime = new Date()
          this.chatStore.save2Local()
        }
      },

      async submitNewMessage(input:string){
        const message = this.createNewMessageAndSave(input)
        if(!message) return
        
        await this.sendMessageToServer(message)
        this.scrollToBottom()
      },


      handleExport(){
        const d = this.ndialog.warning({
          title: '生成分享图片',
          content: '立即将当前对话内容生成长图用以分享？',
          positiveText: '立即生成',
          negativeText: '取消',
          closable: false,
          onPositiveClick: async () => {
            try {
              d.loading = true
              const ele = document.getElementById('image-wrapper')
              const canvas = await html2canvas(ele as HTMLDivElement, {
                useCORS: true,
              })
              const imgUrl = canvas.toDataURL('image/png')
              const tempLink = document.createElement('a')
              tempLink.style.display = 'none'
              tempLink.href = imgUrl
              tempLink.setAttribute('download', 'chat-shot.png')
              if (typeof tempLink.download === 'undefined')
                tempLink.setAttribute('target', '_blank')

              document.body.appendChild(tempLink)
              tempLink.click()
              document.body.removeChild(tempLink)
              window.URL.revokeObjectURL(imgUrl)
              d.loading = false
              this.nmsg.success('导出成功')
              Promise.resolve()
            }
            catch (error: any) {
              this.nmsg.error('抱歉，出错了')
            }
            finally {
              d.loading = false
            }
          },
        })

      },

      deleteMessage(msg: Models.Message){
        this.chatStore.removeMessage(msg)
      },

      lauchNewConversation() {
        if(  !this.session.user
          || !this.chatStore.activeChat
          || !this.chatStore.activeConv
          || this.chatStore.activeConv.messages.length==0) 
          return
        
        const chat = this.chatStore.activeChat
        const conv = {
          id: uuid4(),
          chatId: chat.id,
          userId: this.session.user.id,
          botId: chat.botId,
          messages: [ ] as Models.Message[],
          createTime: new Date(),
          updateTime: new Date(),
        } as Models.Conversation
        this.chatStore.addConversation(conv)
      },

      isInversion( msgType: Models.MessageType ){
          return msgType === Models.MessageType.User
      },
      getConvRowCssClass(index: number){
        return index % 2 === 0 ? 'even' : 'odd'
      },

      isMessageInLoading(msg: Models.Message) {
        return msg.status===Models.MessageStatus.New || msg.status===Models.MessageStatus.Pendding
      },

      messageError(msg: Models.Message) {
        return  msg.status == Models.MessageStatus.Error ? msg.error?.info : undefined
                 
      },

      scrollToChatListBottom(){
        (this.$refs.chatListBottom as HTMLDivElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
      },

      handleScroll() {
        if(this.scrollRef){
          this.scrollHeight = this.scrollRef.scrollHeight
        }
      }
  }
}

</script>

<template>
    <div v-if="chatStore.activeChat" class="flex flex-col w-full h-full">
      <HeaderComponent
        :chat="chatStore.activeChat"
        :title="botDicts[chatStore.activeChat.botId].name"
        @export="handleExport" @stop-recieve="stopRecieveToken"
      />
      <!-- <div id="chat-list">
        <template v-if="chat.conversations?.length">
          <div v-for="conv in chat.conversations" class="conversation">
            <template v-for="message in conv.messges ">
              <NewMessageComponent :message="message" />
            </template>
          </div>
        </template>
        <template v-else>
          ... no messages ...
        </template>
      </div> -->
      
      <main class="flex-1 overflow-hidden" id="main-chat-area">
        <div
          id="scrollRef"  ref="scrollRef" @scroll="handleScroll"
          class="h-full overflow-hidden overflow-y-auto" >
          <div
            id="image-wrapper"
            class="w-full max-w-screen-xl m-auto dark:bg-[#101014]"
          >
            <template v-if="!chatStore.activeChat?.conversations?.length">
              <div class="flex items-center justify-center mt-4 text-center text-neutral-300">
                <SvgIcon icon="ri:bubble-chart-fill" class="mr-2 text-3xl" />
                <span>Aha~No Message</span>
              </div>
            </template>
            <template v-else>
              <div v-if="chatStore.activeChat">
                <div v-for="(conv, index) in chatStore.activeChat.conversations" 
                  class="conversation" :class="getConvRowCssClass(index)">
                  <template v-if="conv?.messages?.length">
                    <MessageComponent
                    v-for="msg in conv.messages"
                    :message="msg"
                    :key="msg.id"
                    :date-time="new Date(msg.updateTime).toLocaleTimeString()"
                    :text="msg.content"
                    :inversion="isInversion(msg.msgType)"
                    :loading="isMessageInLoading(msg)"
                    :error = "messageError(msg)"
                    @delete="deleteMessage"
                  />
                  </template>
                </div>
                <!-- <div id="chat-list-bottom" ref="chatListBottom">------------------</div> -->
              </div>
            </template>
          </div>
        </div>
      </main>
      <FooterComponent :chat="chatStore.activeChat" @send-new-message="submitNewMessage" @stop-recieve="stopRecieveToken" @launch-new-conversation="lauchNewConversation"/>
    </div>
  </template>

<style scoped>
  #chat-list{
    width: 100%;
  }
 .conversation{
    padding: 16px;
  }
  .conversation.even {
    background-color: #ffffff;
  }

  .conversation.odd {
    background-color: #f5f5f5;
  }

</style>
  