import type { Router } from 'vue-router'
// import { userSessionStoreWithout } from '@/store'

export function setupRouterGuard(router: Router) {

  router.beforeEach(async (to, from, next) => {
    if (to.path === '/500')
      next({ name: 'Root' })
    else {
      // const session = userSessionStoreWithout()
      // session.checkSessionStatus()
      next()
    }
  })

  
}
