import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import { setupRouterGuard } from './permission'
import { RootLayout } from '@/views/layout'
import { FriendPage, BotDetail } from '@/views/friend'
import { ChatPage, ChatWindow } from '@/views/chat'
import { FindPage } from '@/views/find'
import { SettingsPage, ProfileVue, FeedbackVue, AboutVue } from '@/views/settings'
import 'vue-router';

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean,
    showBottomNaviBar?: boolean
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Root',
    component: RootLayout,
    redirect: '/chat',
    children: [
      {
        path: '/chat',
        name: 'ChatPage',
        component: ChatPage,
        meta: {
          showBottomNaviBar: true
        },
        children:[
          {
            path: '/chat/:chatId',
            name: 'ChatWindow',
            component: ChatWindow,
            props: true,
            meta: {
              showBottomNaviBar: false
            }
          }
        ]
      },
      {
        path: '/friend',
        name: 'FriendPage',
        component: FriendPage,
        meta: {
          showBottomNaviBar: true
        },
        children:[
          {
            path: '/friend/:botId',
            name: 'FriendPageBotDetail',
            component: BotDetail,
            // props: true,
            props: (route) => ({
              botId: route.params.botId,
              closable: false
            }),
            meta: {
              showBottomNaviBar: false
            }
          }
        ]
      },
      {
        path: '/find',
        name: 'FindPage',
        component: FindPage,
        meta: {
          showBottomNaviBar: true
        },
        children:[
          {
            path: '/find/:botId',
            name: 'FindPageBotDetail',
            component: BotDetail,
            props: (route) => ({
              botId: route.params.botId,
              closable: true
            }),
            meta: {
              showBottomNaviBar: false
            }
          },
        ]
      },
      {
        path: '/settings',
        name: 'SettingsPage',
        component: SettingsPage,
        meta: {
          showBottomNaviBar: true
        },
        children:[
          {
            path: 'profile',
            name: 'ProfileVue',
            component: ProfileVue,
            meta: {
              showBottomNaviBar: false
            },
          },
          {
            path: 'feedback',
            name: 'FeedbackVue',
            component: FeedbackVue,
            meta: {
              showBottomNaviBar: false
            },
          },
          {
            path: 'about',
            name: 'AboutVue',
            component: AboutVue,
            meta: {
              showBottomNaviBar: false
            },
          },
        ]
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    component: () => import('@/views/exception/404.vue'),
  },

  {
    path: '/500',
    name: '500',
    component: () => import('@/views/exception/500.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    redirect: '/404',
  },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // scrollBehavior: () => ({ left: 0, top: 0 }),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log('page hash value: ' + to.hash)
      return { selector: to.hash, behavior: 'smooth', }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

setupRouterGuard(router)


export async function setupRouter(app: App) {
  app.use(router)
  await router.isReady()
}

