<script lang="ts">
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { NLayout, NLayoutHeader, NLayoutContent, NLayoutSider } from 'naive-ui'
// import { useRouter } from 'vue-router'
import { useChatStore, useAppStore, useFriendStore } from '@/store'
import { Models } from '@/typings'
import ChatList from './chatList.vue'
import ChatWindow from './chatWindow/chatWindow.vue'

export default { 
  components:{ 
    NLayout, NLayoutHeader, NLayoutContent, NLayoutSider,
    ChatList, ChatWindow
  },
  prop(){
    return {
      chatId: {
        type: String,
        require: true
      }
    }
  },
  data(){
    return {
      chatStore : useChatStore(),
      friendStore: useFriendStore(),
      appStore: useAppStore(),
      isMobile : useBasicLayout().isMobile
    }
  },
  computed:{
    routeChatId(){
      return this.$route?.params.chatId?.toString()
    },
    hasChatId(){
      return !!this.routeChatId
    },
    hasValidChatId(){
      return !!this.routeChatId && this.friendStore.hasBot(this.routeChatId)
    },
    needRedirect(){
      // console.log(`FriendPage needRedirect -> ${ this.$route.matched[1]?.name?.toString() }`)
      // 此判断用以处理离开当前页面的情况
      if(this.$route.matched[1]?.name?.toString() !== Models.PageName.ChatPage){
        return false
      }

      if(this.isMobile){
        return this.hasChatId && !this.hasValidChatId
      }
      else{
        return !this.hasValidChatId
      }
    },
    showSider(){
      return (!this.isMobile && this.chatStore.showSider) || (this.isMobile && !this.hasValidChatId)
    },

  },
  mounted() {
    this.needRedirect &&  this.redirectToDefaultChild()
    this.loadStateFromRoute(this.routeChatId)
  },
  watch:{
    routeChatId(chatId){
      this.loadStateFromRoute(chatId)
    },
    needRedirect(isNeed){
      isNeed &&  this.redirectToDefaultChild()
    }
  },
  methods:{
    changeShowSider(show: boolean){
      this.chatStore.showSider = !show
    },
    redirectToDefaultChild(){
      let chatId = this.chatStore.activeChatId ?? 
        (this.chatStore.chats.length ? this.chatStore.chats[0] : null )
      
        if(!this.isMobile && chatId){
        this.$router.replace('/chat/' + chatId)
      }
      else {        
        this.$router.replace('/chat')
      }
    },
    loadStateFromRoute(chatId: string){
      if(this.hasValidChatId){
        if(!this.chatStore.hasChat(chatId)){
          this.chatStore.addChat(chatId)
        }
        else{
          this.chatStore.changeActiveChat(chatId)
        }
      }
    }
  },
}
</script>

<template>
  <NLayout id="root-layout" has-sider position="absolute">
    <NLayoutSider id="sider-bar" :width="isMobile ? '100%' : 320" :collapsed-width="0" bordered 
       :show-trigger="isMobile ? Boolean(0) : 'arrow-circle'" :collapsed="!showSider"
       :on-update:collapsed="changeShowSider">
      <NLayoutHeader id="sider-bar-header" bordered >
        我的聊天
      </NLayoutHeader>
      <NLayoutContent id="sider-bar-content"  position="absolute" :native-scrollbar="false">
        <ChatList  />
      </NLayoutContent>
    </NLayoutSider>
    <NLayoutContent id="main-area" >
      <RouterView id="main-area" ></RouterView>
    </NLayoutContent>
  </NLayout>
</template>

<style scoped>
  #root-layout{
    /* background-color: azure; */
  }
  #sider-bar{
    background-color: #e8e8e8;
  }
  #sider-bar-header{
    height: 56px;
    border-bottom: 1px gray;
    line-height: 100%;
    vertical-align: middle;
    text-align: center;
    font: 16px bold;
    padding: 12px;
  }
  #sider-bar-content{
    background-color: transparent;
    top: 60px;
  }
  /* #main-area{
    background-color: #fdfdfd;
  } */
  #header{
    text-align: center;
    padding: 12px;
    font: 16px bold;
    /* background-color: lightgray; */
  }
</style>