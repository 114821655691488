<script lang="ts">
import { PropType } from 'vue'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { NAutoComplete, NButton, NInput, useDialog, useMessage, NTooltip } from 'naive-ui'
import { HoverButton, SvgIcon } from '@/components/common'
import { Models } from '@/typings'

export default{
    components: {
        NAutoComplete, NButton, NInput, useDialog, useMessage,NTooltip,
        HoverButton, SvgIcon
    },
    props:{
        chat:{
            type: Object as PropType< Models.Chat  | null>,
            required: true,
        }
    },
    setup(){
        return {
            nmsg : useMessage(),
        }
    },
    emits: ['send-new-message','change-chat-mode','launch-new-conversation', 'stop-recieve'],
    data(){
        return {
            input: '',
            isMobile: useBasicLayout().isMobile,
        }
    },
    computed: {
        placeholder() {
            if (this.isMobile)
                return '大胆说出你的问题...'
            return '大胆说出你的问题...（Shift + Enter = 换行； Enter = 发送）'
        },

        showStopButton() {
            if( this.chat?.busyMode?.isBusy  && this.chat?.busyMode.type){
                return ['Waitting','Recieving'].includes(this.chat.busyMode.type)
            }
            else{
                return false
            }
        },
    },
    methods: {
        handleChatModeChange(){
            if(!this.chat) return

            this.chat.isChatMode = !this.chat.isChatMode
            console.log('聊天模式已切换到'+ this.chat.isChatMode)
            this.$emit('change-chat-mode', this.chat.isChatMode)
        },

        handleNewConversation(){
            if(!this.chat) return

            this.$emit('launch-new-conversation')
            this.nmsg.success('已开启新话题')
        },

        handleSend(){
            let input = this.input.trim()
            if(input.length){
                this.$emit('send-new-message', input)
                this.input = ''
            }
        },

        handleEnter(event: KeyboardEvent) {
            if (!this.isMobile) {
                if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                this.handleSend()
                }
            }
            else {
                if (event.key === 'Enter' && event.ctrlKey) {
                event.preventDefault()
                this.handleSend()
                }
            }
        },

        handleStop(){
            this.$emit('stop-recieve')
        },

        footerClass() {
            let classes = ['p-4']
            if (this.isMobile)
                classes = ['sticky', 'left-0', 'bottom-0', 'right-0', 'p-2', 'pr-3', 'overflow-hidden']
            return classes
        }
    }
}

</script>

<template v-if="chat">
<footer :class="footerClass">
<div class="w-full max-w-screen-xl m-auto">
    <div v-if="chat?.busyMode?.isBusy" class="status-info">
        <span>{{ chat.busyMode.info }}</span>
        <HoverButton v-if="showStopButton" @click="handleStop"   
            tooltip="中止逐字接收" class="stop-button">
            <span class="text-xl" >
                <SvgIcon icon="mdi:stop-circle-outline" />
            </span>
        </HoverButton>
    </div>
    <div v-else class="flex items-center justify-between space-x-2">
        <!-- <HoverButton @click="handleChatModeChange" v-if="chat?.isChatMode"
        tooltip="当前为连续会话模式：每次发送新消息默认继续当前话题，上下文信息更完整。">
            <span class="text-xl" :class="{ 'text-[#4b9e5f]': chat?.isChatMode, 'text-[#a8071a]': !chat?.isChatMode }">
                <SvgIcon  icon="material-symbols:chat-outline"  />
            </span>
        </HoverButton>
        <HoverButton @click="handleChatModeChange"  v-else
        tooltip="当前为快速问答模式，每次发送新消息默认开启新话题，响应速度更快。">
            <span class="text-xl" :class="{ 'text-[#4b9e5f]': chat?.isChatMode, 'text-[#a8071a]': !chat?.isChatMode }">
                <SvgIcon icon="mdi:comment-question-outline" />
            </span>
        </HoverButton> -->
        <HoverButton @click="handleNewConversation"   v-if="chat?.isChatMode"
        tooltip="开启新话题">
            <span class="text-xl text-[#4b9e5f]">
                <SvgIcon icon="material-symbols:chat-add-on-outline-rounded" />
            </span>
        </HoverButton>
        <!-- <NAutoComplete v-model:value="prompt" :options="searchOptions" :render-label="renderOption"> -->
        <NAutoComplete v-model:value="input" >
            <template #default="{ handleInput, handleBlur, handleFocus }">
            <NInput clearable maxlength="1000" show-count
                ref="inputRef"
                v-model:value="input"
                type="textarea"
                :placeholder="placeholder"
                :autosize="{ minRows: 1, maxRows: isMobile ? 4 : 8 }"
                @input="handleInput"
                @focus="handleFocus"
                @blur="handleBlur"
                @keypress="handleEnter"
            />
            </template>
        </NAutoComplete>
        <NButton type="primary" :disabled="chat?.busyMode.isBusy" @click="handleSend">
            <template #icon>
            <span class="dark:text-black">
                <SvgIcon icon="ri:send-plane-fill" />
            </span>
            </template>
        </NButton>
    </div>
</div>
</footer>
</template>
<style scoped>
    footer{
        padding: 12px;
        border-top: 1px solid rgb(242, 242, 242)
    }
    .status-info{
        width: 100%;
        line-height: 40px;
        vertical-align: middle;
        text-align: center;
    }

    .status-info .stop-button {
        float: right;
        color: red;
    }
</style>