<script lang="ts">
import { defineComponent, Component, h } from 'vue'
import { List as ListItemIcon } from '@vicons/ionicons5'
import { NSpace, NLayout, NLayoutSider, NLayoutHeader, NLayoutContent, NMenu, NIcon, NButton, MenuOption, NAnchor, NAnchorLink, NGrid, NGridItem, NAvatar } from 'naive-ui'
// import { RouterLink } from 'vue-router'
import { useFriendStore, useAppStore } from '@/store'
import { Models } from '@/typings'
import { BotDetail } from '@/views/friend'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { SvgIcon } from '@/components/common'

function renderIcon (icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}


export default defineComponent({
    components:{
        NSpace, NLayout, NLayoutSider, NLayoutHeader, NLayoutContent, NMenu, NIcon, NButton, NAnchor, NAnchorLink, NGrid, NGridItem, NAvatar,
        BotDetail, SvgIcon
    },
    data(){
        const botsLibrary = useFriendStore().bots
        const botsCategory = [
            {
                mainCategory: 'recommend',
                key: 'hot',
                title: '最受欢迎',
                bots: botsLibrary.filter(bot => bot.defaultFav),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.Assistant,
                title: '个人助理',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.Assistant),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.Text,
                title: '文字创作',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.Text),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.Lanuage,
                title: '语言学习',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.Lanuage),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.Counselor,
                title: '咨询顾问',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.Counselor),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.Code,
                title: '信息技术',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.Code),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.Media,
                title: '图片视频',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.Media),
            },
            {
                mainCategory: 'productivity',
                key: Models.BotCategory.ProdTool,
                title: '实用工具',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.ProdTool),
            },
            {
                mainCategory: 'entertainment',
                key: Models.BotCategory.DailyRole,
                title: '日常闲聊',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.DailyRole),
            },
            {
                mainCategory: 'entertainment',
                key: Models.BotCategory.FilmRole,
                title: '影视角色',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.FilmRole),
            },
            {
                mainCategory: 'entertainment',
                key: Models.BotCategory.HistoryRole,
                title: '历史人物',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.HistoryRole),
            },
            {
                mainCategory: 'entertainment',
                key: Models.BotCategory.EntTool,
                title: '娱乐工具',
                bots: botsLibrary.filter(bot => bot.category === Models.BotCategory.EntTool),
            }
        ]

        const rcmMenuOptions = [] as MenuOption[]
        const prodMenuOptions = [] as MenuOption[]
        const entMenuOptions = [] as MenuOption[]

        botsCategory.forEach((category) => {
            switch (category.mainCategory) {
                case 'recommend':
                    rcmMenuOptions.push({
                        label: category.title,
                        key: category.key,
                        icon: renderIcon(ListItemIcon)
                    })
                    break;

                case 'productivity':
                    prodMenuOptions.push({
                        label: category.title,
                        key: category.key,
                        icon: renderIcon(ListItemIcon)
                    })
                    break;

                case 'entertainment':
                    entMenuOptions.push({
                        label: category.title,
                        key: category.key,
                        icon: renderIcon(ListItemIcon)
                    })
                    break;
            }
        })

        const menuOptions = [
            {
                type: 'group',
                label: '系统推荐',
                key: 'productivity',
                children: rcmMenuOptions
            },
            {
                type: 'group',
                label: '效率提升',
                key: 'productivity',
                children: prodMenuOptions
            },
            {
                type: 'group',
                label: '休闲娱乐',
                key: 'entertainment',
                children: entMenuOptions
            }

        ]

        return {
            appStore: useAppStore(),
            friendStore: useFriendStore(),
            botsCategory: botsCategory,
            menuOptions: menuOptions,
            activeCategoryKey: menuOptions[0].key,
            isMobile: useBasicLayout().isMobile,
            mShowSider: false,
            hoverBotId: "",
            hoverTimerId: null as  NodeJS.Timeout | null
        }
    },
    computed:{
        activeCategory(){
            return this.botsCategory.find(category => category.key === this.activeCategoryKey)
        },
        activeBotId(){
            const botId = this.$route?.params.botId?.toString()?.trim()
            return botId ?? ''
        },
        hasBotId(){
            return !!this.activeBotId
        },
        hasValidBotId(){
            return this.hasBotId && this.friendStore.hasBot(this.activeBotId)
        },
        showSider(){
            return !this.isMobile || (this.isMobile && this.mShowSider )
        }
    },
    watch:{
        'activeBotId': function (newValue, oldValue) {
            // 此判断用以处理离开当前页面的情况
            if(this.$route.matched[1]?.name?.toString() !== Models.PageName.FindPage){
                // console.log('leaving find page ... go to: ' + this.$route.matched[1]?.name?.toString())
                return 
            }
            // console.log(`activeBotId changed : ${oldValue} -> ${newValue}`)

            if( this.hasValidBotId){
                this.mShowSider = false
            }
            else if(this.hasBotId){
                this.$router.replace('/find')
            }
        },
        'activeCategoryKey': function(newValue, oldValue) {
            // console.log('activeCategoryKey:', oldValue, '-->', newValue)
            if(newValue && this.$refs[newValue]){
                (this.$refs[newValue] as HTMLDivElement[])[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
                this.mShowSider = false
            }
        }
    },
    methods: {
        favHandler(bot: Models.Bot){
            if(bot)
                bot.isFav = !bot.isFav
                useFriendStore().save2Local()
        },
        handleShowSider(){
            this.mShowSider = !this.mShowSider
        },
        changeActiveMenu(value:string){
            // console.log('changeActiveMenu -> ' + value)
            
        },
        hoverBotCard(botId:string){
            this.hoverBotId = botId
            if(this.hoverTimerId){
                clearTimeout(this.hoverTimerId)
            }
            // TODO: 该定时器未生效，需查明原因
            this.hoverTimerId = setTimeout(() => {
                console.log('timeout')
                this.hoverBotId = ""
            }, 2000)
        }
    },
    mounted(){
    },
})
</script>

<template>
    <NLayout has-sider position="absolute">
        <NLayoutSider  bordered :width="200" :collapsed-width="0" :collapsed="isMobile && !showSider">
            <NLayoutHeader class="page-header">
                <div>发现更多</div>
            </NLayoutHeader>
            <NLayoutContent class="sider-bar-content">
                <NMenu :options="menuOptions" v-model:value="activeCategoryKey" @update:value="changeActiveMenu"/>
            </NLayoutContent>
        </NLayoutSider>
        <NLayoutContent>
            <NLayout position="absolute" class="main-content" :native-scrollbar="false">
                <NLayoutHeader  class="page-header flex items-center justify-between min-w-0 overflow-hidden h-14"  position="absolute">
                    <div class="flex items-center" v-if="isMobile">
                        <button class="flex items-center justify-center w-11 h-11" @click="handleShowSider" >
                            <SvgIcon  class="text-2xl" icon="mdi:menu" />
                        </button>
                    </div>
                    <h1 class="flex-1 px-4 pr-6 overflow-hidden cursor-pointer select-none text-ellipsis whitespace-nowrap">
                        <!-- {{ activeCategory?.title }} -->
                        有趣的AI朋友们 @ AIFriends.Chat
                    </h1>
                </NLayoutHeader>
                <NLayoutContent id="main-page-content" positon="absolute"  :native-scrollbar="false">
                    <div v-for="category in botsCategory" :id="category.key" :ref="category.key" class="bots-category-item">
                        <div class="title">
                            <span>{{ category.title }}</span>
                        </div>
                        <n-grid cols="2 600:3 800:4 1000:5 1200:6">
                            <n-grid-item v-for="bot in category.bots" >
                                <div class="bot-card" :class="{'hover': bot.id===hoverBotId}" @click="hoverBotCard(bot.id)">
                                    <NAvatar round class="avatar"  :size="100" :src="bot.header"/>
                                    <div>
                                        <div class="name">{{ bot.name }}</div>
                                        <div class="sologon">{{ bot.slogon }}</div>
                                    </div>
                                    <div class="op-panel">
                                        <NSpace vertical justify="center">
                                            <template v-if="bot?.isFav">
                                                <RouterLink :to="('/chat/'+bot?.id)" >
                                                    <NButton type="primary" >开始聊天</NButton>
                                                </RouterLink>
                                                <NButton ghost @click="favHandler(bot)">移除好友</NButton>
                                            </template>
                                            <template v-else>
                                                <NButton type="primary" @click="favHandler(bot)">加为好友</NButton>
                                            </template>
                                            <RouterLink :to="('/find/'+bot?.id)" >
                                                <NButton strong secondary type="primary" >查看详情</NButton>
                                            </RouterLink>
                                        </NSpace>
                                    </div>
                                </div>
                            </n-grid-item>
                        </n-grid>
                    </div>
                </NLayoutContent>
            </NLayout>

            <RouterView id="bot-detail"  v-if="activeBotId" ></RouterView>
        </NLayoutContent>
    </NLayout>
</template>

<style scoped>

.main-content{
    min-width: 320px;
}

#main-page-content{
    padding: 6px;
}
.bots-category-item .title{
    line-height: 60px;
    margin: 16px;
    /* padding-top: 61px; */
    font: 18px bold;
}
.bot-card {
    position: relative;
    width: 160px;
    height: 200px;
    background-color: white;
    text-align: center;
    margin: 12px 6px;
    padding: 12px;
    border-radius: 12px;
}

.bot-card  .name{
    font: 16px bold;
    line-height: 30px;
}
.bot-card .sologon{
    /* margin: 12px; */
    color: #7a7a7a;
    max-lines: 2;
}

.bot-card .op-panel {
    display: none;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: rgba(255,255,255,0.95);
    border-radius: 12px;
}
.bot-card button{
    margin: 4px;
}
.bot-card:hover .op-panel, .bot-card.hover .op-panel{
    display: block;
}

.bot-card .op-panel .n-space {
    width: 100%;
    height: 100%;
}

#bot-detail {
    z-index: 3;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
</style>