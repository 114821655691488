<script lang="ts">
import { NCard, NSpace, NAvatar } from 'naive-ui'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useFriendStore } from '@/store'

export default{
    components: {
      NCard, NSpace, NAvatar
    },
    // props: {
    // },
    emits: [],
    data(){
        return {
            friendStore :  useFriendStore(),
            isMobile : useBasicLayout().isMobile,
        }
    },

    computed: {
      getMobileClass(){
        if (this.isMobile) {
          return {
            position: 'fixed',
            zIndex: 50,
          }
        }else{
          return {}
        }
      },
      mobileSafeArea(){
        if (this.isMobile) {
          return {
            paddingBottom: 'env(safe-area-inset-bottom)',
          }
        }
        return {}
      }
    },

    mounted(){
    },

    watch:{
    },

    methods: {
    },
}
</script>

<template>
  <template v-if="!friendStore.favedBots.length">
      <p>Aha ~ no bots is your friend ...</p>
  </template>
  <template v-else>
    <div id="page-root">
      <router-link v-for="bot of friendStore.favedBots"  :key="bot.id"  :to="('/friend/'+bot.id)">
        <NCard size="huge" 
        class="friend-card"  :class="{ active: bot===friendStore.activeBot }" content-style="padding:0;">
          <NSpace  >
            <NAvatar round class="avatar"  :size="80" :src="bot.header"/>
            <div class="friend-card-content">
              <div class="name">{{ bot.name }}</div>
              <div class="slogon">{{ bot.slogon }}</div>
            </div>
          </NSpace>
        </NCard>
      </router-link>
    </div>
  </template>
</template>

<style scoped>
  #page-root{
    /* margin-right: 12px; */
    background: transparent;
  }
  .friend-card{
    margin: 2px 0 2px 0;
    height: 80px;
    max-height: 80px;
    border-radius: 80px 0 0 80px;
  }
  .friend-card.active{
    background-color: azure;
  }
  .friend-card:hover{
    /* background-color: lightcyan; */
    transform: 
      translateY(-5px) 
      scaleY(1.05);
    border-top: 1px solid lightgray;
  }
  .friend-card .n-card__content{
    padding: 12px;
  }
  .friend-card-content{
    max-width: 120px;
    padding: 12px;
    white-space: nowrap;
  }


  .friend-card .name{
    font:  20px bold;
    padding: 0 0 6px 0;
  }
  .friend-card .slogon{
    color: #7a7a7a;
  }
</style>