<script lang="ts">
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { NLayout, NLayoutHeader, NLayoutContent, NLayoutSider } from 'naive-ui'
import BotList from './botList.vue'
import BotDetail from './botDetail.vue'
import { useFriendStore, useAppStore } from '@/store'
import { Models } from '@/typings'

export default {
  components:{ 
    NLayout, NLayoutHeader, NLayoutContent, NLayoutSider,
    BotList, BotDetail  
  },
  data() {
    return {
      friendStore : useFriendStore(),
      appStore : useAppStore(),
      isMobile : useBasicLayout().isMobile
    }
  },
  mounted(){
    this.needRedirect && this.redirectToDefaultChild()
  },
  computed:{
    routeBotId(){
      return this.$route?.params.botId?.toString()
    },
    hasBotId(){
      return !!this.routeBotId
    },
    hasValidBotId(){
      return this.hasBotId && this.friendStore.hasBot(this.routeBotId)
    },
    showSider(){
      return (!this.isMobile && this.friendStore.showSider) || (this.isMobile && !this.hasValidBotId)
    },
    needRedirect(){
      // console.log(`FriendPage needRedirect -> ${ this.$route.matched[1]?.name?.toString() }`)
      // 此判断用以处理离开当前页面的情况
      if(this.$route.matched[1]?.name?.toString() !== Models.PageName.FriendPage){
        return false
      }

      if(this.isMobile){
        return this.hasBotId && !this.hasValidBotId
      }
      else{
        return !this.hasValidBotId
      }
    }
  },
 
  watch: {
    routeBotId(botId){
      if(this.hasValidBotId){
        this.friendStore.setActiveBotId(botId)
      }
    },
    needRedirect(isNeed){
        isNeed && this.redirectToDefaultChild()
    }
  },

  methods:{
    redirectToDefaultChild(){
      let botId = this.friendStore.activeBotId ?? 
        (this.friendStore.favedBots.length ? this.friendStore.favedBots[0] : null )

      if(!this.isMobile && botId){
        this.$router.replace('/friend/' + botId)
      }
      else {        
        this.$router.replace('/friend')
      }
    }
  },
}
</script>

<template>
  <NLayout id="root-layout" has-sider position="absolute">
    <NLayoutSider id="sider-bar" :width="isMobile ? '100%' : 320" :collapsed-width="0"  :collapsed="!showSider" >
      <NLayoutHeader id="sider-bar-header" class="page-header" >
        我的AI好友
      </NLayoutHeader>
      <NLayoutContent id="sider-bar-content"  position="absolute" :native-scrollbar="false">
        <BotList />
      </NLayoutContent>
    </NLayoutSider>
    <!-- <NLayoutContent id="main-area" :native-scrollbar="false"> -->
      <RouterView id="main-area"  ></RouterView>
    <!-- </NLayoutContent> -->
  </NLayout>
</template>

<style scoped>
  #root-layout{
  }
  #sider-bar{
    background-color: #e8e8e8;
  }
  #sider-bar-header{
    height: 56px;
    border-right: 1px solid rgb(244, 244, 244);
  }
  #sider-bar-content{
    background-color: transparent;
    top: 56px;
  }
  #main-area{
    background-color: #f6f6f6;
  }
  #header{
    text-align: center;
    padding: 12px;
    font: 16px bold;
  }
</style>