export interface ChatState{
    chats: Chat[],
    activeChatId: string | null
    showSider: boolean
}

export interface Chat{
    id: string
    userId: string
    botId: string
    conversations: Conversation[]
    newMsgCount?: number
    isChatMode: boolean
    busyMode: ChatBusyMode
    summary?: string | undefined
    createTime: Date
    updateTime: Date
}

export interface ChatBusyMode{
    isBusy: boolean,
    type?: 'Sendding' | 'Waitting' | 'Recieving',
    info?: string
}

export enum MessageType {
    System = 'System',
    AI = 'AI',
    User = 'User',
    Client = 'Client',
}

export enum MessageStatus{
    New, Pendding, Done, Error, Stop
}

export interface MessageError{
    type: 'NetworkError' | 'MsgBroken' |  'ModelError' | 'Unknow' ,
    info: string,
}

export interface  Message {
    id: string
    parentMsgId: string
    replyMsgId?: string
    convId: string
    chatId: string
    clientId: string
    userId: string
    msgType: MessageType
    status: MessageStatus
    content: string
    rawContent?: string
    error?: MessageError | null
    createTime: Date
    updateTime: Date
}

export interface  Conversation{
    id: string
    chatId: string
    userId: string
    botId: string
    messages: Message[]
    summary?: string
    createTime: Date
    updateTime: Date
}
