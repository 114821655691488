<script lang='ts'>
import { NButton, NInput, NModal, NSpace, NForm, NInputGroup, NFormItem, useMessage, FormRules, FormInst, FormValidationError } from 'naive-ui'
import { ApiError, ApiStatusCode, HttpClient } from '@/api'
import { useSessionStore } from '@/store'
import { Models, RegExpRules } from '@/typings'
import { ref } from 'vue'

export default {
    components: {
        NButton, NInput, NModal, NSpace, NInputGroup, NForm, NFormItem, useMessage
    },
    setup() {
        return {
            nmsg: useMessage(),
            rules: {
                phone: [{
                    key: 'phone',
                    required: true,
                    validator: (rule, value: string) => RegExpRules.PhoneNumber_China.test(value),
                    trigger: ['blur', 'input'],
                    message: '手机号码格式错误'
                }],
                smsCode: [{
                    required: true,
                    validator: (rule, value: string) => RegExpRules.SmsCode.test(value) ,
                    trigger: ['blur', 'input'],
                    message: '验证码格式错误'
                }],
            } as FormRules,
            refMainFrom: ref<FormInst | null>(null) 
        }
    },
    data() {
        return {
            session: useSessionStore(),
            model: {
                phone: "",
                smsCode: "",
            },
            smsLoading: false,
            loginLoading: false,
            countdown: 0,
        }
    },
    computed: {
        smsButtonEnable() {
            return (this.model.phone && !this.smsLoading && !this.loginLoading && !this.countdown)
        },
        loginButtonEnable() {
            return (this.model.phone && this.model.smsCode && !this.smsLoading && !this.loginLoading)
        }
    },
    methods: {
        async handleGetSmsCode() {
            if( ! await this.validateForGetSmsCode())
                return 

            try {
                this.smsLoading = true
                await HttpClient.fetchSmsCode(this.model.phone)
                this.nmsg.success('验证码已发送')

                this.countdown = 60;
                const timer = setInterval(() => {
                    this.countdown -= 1;
                    if (this.countdown <= 0) {
                        clearInterval(timer);
                    }
                }, 1000);
            }
            catch (error) {
                if (error instanceof ApiError) {
                    const e = error as ApiError<string>
                    switch (e.errorCode) {
                        case ApiStatusCode.Error_Network:
                        case ApiStatusCode.Error_Server:
                        case ApiStatusCode.Error_Params: {
                            this.nmsg.error(`${e.message},验证码发送失败`)
                            break
                        }
                        case ApiStatusCode.Error:
                            this.nmsg.error('验证码发送失败，请稍后重试')
                    }
                }
                else {
                    this.nmsg.error('验证码发送失败，请稍后重试')
                }
            }
            finally {
                this.smsLoading = false
            }
        },
        async handleLogin() {
            if(! await this.validateForLogin()) 
                return

            try {
                this.loginLoading = true
                const user = await HttpClient.logInBySms(this.model.phone, this.model.smsCode) as Models.User
                this.session.updateSession(user)
                this.nmsg.success('登录成功')
            }
            catch (error) {
                if (error instanceof ApiError) {
                    const e = error as ApiError<string>
                    this.nmsg.error(e.message)
                }
                else {
                    this.nmsg.error('登录失败，请稍后重试')
                }
            }
            finally {
                this.loginLoading = false
            }
        },
        async validateForGetSmsCode(){
            try{
                await this.refMainFrom?.validate(undefined, (rule) => {
                    return rule?.key === 'phone'
                })
                return true
            }
            catch(e){
                this.handleFormValidError(e)
                return false
            }

        },
        async validateForLogin(){
            try{
                await this.refMainFrom?.validate()
                return true
            }
            catch(e){
                this.handleFormValidError(e)
                return false
            }
        },
        handleFormValidError(e: any){
            const errors = e as FormValidationError[]
            this.nmsg.error(errors[0][0].message as string)
        },
        onlyAllowNumber: (value: string) => !value || RegExpRules.Numbers.test(value)
    }
}
</script>

<template>
    <NModal show>
        <div class="p-10 bg-white rounded dark:bg-slate-800">
            <div class="space-y-4">
                <header class="space-y-2">
                    <img src="/logo/logo-banner-640.jpeg" alt="AI Friends" class="logo-banner">
                    <h2 class="text-2xl font-bold text-center text-slate-800 dark:text-neutral-200">
                        手机登录
                    </h2>
                    <p class="text-base text-center text-slate-500 dark:text-slate-500">
                        <!-- {{ $t('common.unauthorizedTips') }} -->
                    </p>
                </header>
                <NForm ref="refMainFrom" :model="model" :rules="rules" size="large">
                    <NFormItem path="phone" :rule="rules.phone"  :show-label="!!false" :show-feedback="!!false">
                        <NInput type="text" placeholder="请输入手机号码"  
                            v-model:value="model.phone" :allow-input="onlyAllowNumber" />
                    </NFormItem>
                    <NFormItem path="smsCode" :rule="rules.smsCode"  :show-label="!!false" :show-feedback="!!false">
                        <NInputGroup>
                            <NInput type="text" placeholder="请输入验证码"  @keydown.enter="handleLogin"
                                v-model:value="model.smsCode" :allow-input="onlyAllowNumber"/>
                            <NButton ghost type="primary" :disabled="!smsButtonEnable" :loading="smsLoading"
                                @click="handleGetSmsCode">
                                {{ countdown > 0 ? ` ${countdown}秒 ` : '获取验证码' }}
                            </NButton>
                        </NInputGroup>
                    </NFormItem>
                    <NButton block type="primary" :disabled="!loginButtonEnable" :loading="loginLoading" size="large"
                        @click="handleLogin">
                        登录
                    </NButton>
                </NForm>
            </div>
        </div>
    </NModal>
</template>
  
<style scoped>
.logo-banner {
    margin-bottom: 36px;
}
.n-modal{
    width: 90%; 
    max-width: 640px
}
.n-form-item {
    margin: 12px 0;
}
</style>
  