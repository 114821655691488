<script lang="ts">
import { NLayout, NLayoutHeader, NLayoutContent, NCard, NSpace, NAvatar, NButton, NIcon } from 'naive-ui'
import { useFriendStore } from '@/store'
import { CloseOutline as CloseIcon} from '@vicons/ionicons5'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { HoverButton, SvgIcon } from '@/components/common'
import { routerUtils } from '@/router/routerUtils'

export default {
    components:{
        NLayout, NLayoutHeader, NLayoutContent, NCard, NSpace, NAvatar, NButton, NIcon, 
        CloseIcon, HoverButton, SvgIcon
    },
    props: {
        botId: {
            type: String,
            required: true,
        },
        closable:{
            type: Boolean,
            default: false
        }
    },
    emits:['onClose', 'onBack'],
    data(){
        return {
            friendStore :  useFriendStore(),
            isMobile: useBasicLayout().isMobile,
            
        }
    },
    methods: {
        favHandler(){
            let bot = this.bot
            if(bot){
                this.friendStore.favBot(bot, !bot.isFav)
            }
        },
        getBotId(){
            
        },
        handleBack(){
            if(routerUtils.canGoBack()){
                this.$router.back()    
            }
            else{
                console.log('can not go back')
                this.$router.replace('/find')
            }
        }
    },
    computed:{
        bot(){
            return this.friendStore.getBotDetailById(this.botId)
        } 
    },
    beforeCreate(){
        // console.log('Detail page before create')
    },
    onMounted(){
        // console.log('Detail page mounted')

    }
}

</script>

<template v-if="bot">
    <NLayout vertical  id="page-root" position="static">
        <NLayoutHeader class="page-header flex items-center justify-between " >
            <div class="flex items-center" v-if="isMobile">
                <button
                    class="flex items-center justify-center w-11 h-11"
                    @click="handleBack" >
                <SvgIcon  class="text-2xl" icon="ic:baseline-arrow-back-ios" />
                </button>
            </div>
            <h1 class="flex-1 px-4 pr-6 overflow-hidden cursor-pointer select-none text-ellipsis whitespace-nowrap" >
                {{ bot?.name }} @ AiFriends.Chat
            </h1>
            <n-button v-if="closable && !isMobile" @click="handleBack" strong secondary id="close-button" circle>
                <template #icon>
                    <n-icon><close-icon /></n-icon>
                </template>
            </n-button>
        </NLayoutHeader>
        <NLayoutContent id="page-content"  position="absolute"  :native-scrollbar="false">
            <div id="profile-card-container">
                <div id="profile-card" >
                    <NAvatar round class="avatar"  :size="320" :src="bot?.header"/>
                    <div>
                        <div id="name">{{ bot?.name }}</div>
                        <div id="sologon">{{ bot?.slogon }}</div>
                        <template v-if="bot?.isFav">
                            <RouterLink :to="('/chat/'+bot?.id)" >
                                <NButton type="primary" >开始聊天</NButton>
                            </RouterLink>
                            <NButton ghost @click="favHandler">- 移除好友</NButton>
                        </template>
                        <template v-else>
                            <NButton type="primary" @click="favHandler">+ 加为好友</NButton>
                        </template>
                    </div>
                </div>
            </div>
            <div id="user-detail">
                <div id="desc" v-bind:innerHTML="bot?.detail?.desc ?? bot?.greeting"></div>
                <div v-if="bot?.detail?.ex">
                    <h2 >会话示例</h2>
                    <ol id="ex-list">
                        <li v-for="ex in bot.detail.ex">
                            <p>{{ ex.caption }}</p>
                            <img :src="ex.img" />
                        </li>
                    </ol>
                </div>
                <!-- <div v-else > <p style="text-align: center;">自我介绍还木有...</p></div> -->
            </div>
        </NLayoutContent>
    </NLayout>
</template>

<style scoped>
    #page-root{
        background-color: #e8e8e8;
    }
    .page-header #close-button {
        float: right !important;
        /* margin: 12px; */
    }
    #page-content{
        min-width: 320px;
        width: 100%;
        top: 56px;
    }
    #profile-card-container{
        width: 100%;
        background-color: azure;
    }
    #profile-card{
        min-width: 320px;
        max-width: 640px;
        margin: 0 auto; 
        padding: 24px;
        background-color:transparent ;
        text-align: center;
    }
    #profile-card .avatar{
        transition: transform 0.3s ease-in-out;
    }
    #profile-card .avatar:hover{
        transform: scaleX(-1);
    }
    #profile-card #name{
        font: 30px bold;
        margin: 12px;
    }
    #profile-card #sologon{
        margin: 12px;
        color: #7a7a7a;
    }
    #profile-card button{
        margin: 12px;
    }
    #user-detail{
        min-width: 320px;
        max-width: 640px;
        margin: 24px auto; 
        padding: 12px;
        letter-spacing: 0.1em;
        line-height: 1.5em ;
    }
    #desc {

    }
    #user-detail :deep(p){
        margin: 24px 0 ;
    }

    #user-detail :deep(h2){
        margin: 24px 0;
        line-height: 2em ;
        font: 1.6em bold;      
        text-align: center;  
    }

    #ex-list{
        list-style-type: upper-roman;
        list-style-position: inside;
        text-align: center;
    }

    #ex-list li{
        margin: 24px 0;
    }

    #ex-list :deep(p){
        margin: 0 0 6px 0;
    }

    #ex-list :deep(img){
        border: lightgray 5px solid;
        border-radius: 12px;
    }
</style>