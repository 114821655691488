<script lang="ts">
import { NCard, NSpace, NAvatar, NBadge, NEllipsis } from 'naive-ui'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { Models } from '@/typings'
import { useChatStore, useFriendStore } from '@/store'

export default{
    components: {
      NCard, NSpace, NAvatar, NBadge, NEllipsis,
    },
    emits: ['onActiveChatChanged'],
    data(){
        return {
            isMobile : useBasicLayout().isMobile,
            chatStore: useChatStore(),
            botDicts: useFriendStore().botDicts
        }
    },

    computed: {
      getMobileClass(){
        if (this.isMobile) {
          return {
            position: 'fixed',
            zIndex: 50,
          }
        }else{
          return {}
        }
      },
      mobileSafeArea(){
        if (this.isMobile) {
          return {
            paddingBottom: 'env(safe-area-inset-bottom)',
          }
        }
        return {}
      }
    },

    mounted(){
    },

    watch:{
    },

    methods: {
      getBot(botId: string): Models.Bot | undefined {
        return this.botDicts[botId]
      },

      getChatSummary(chat: Models.Chat){
        const lastMessage = this.chatStore.getLastMessageInChat(chat)
        return  lastMessage? lastMessage.content : '暂时没有聊天记录...'
      }
    },
}
</script>

<template>
  <template v-if="!chatStore.chats.length">
      <p>no chats is your list</p>
  </template>
  <template v-else>
    <div id="page-root" >
      <RouterLink v-for="chat of chatStore.sortedChats"  :key="chat.id"   :to="('/chat/'+chat.id)" >
        <NBadge dot processing
        class="chat-badge"  :show="Boolean(chat.newMsgCount)"  :offset="[-24,24]"  >
            <NCard  :bordered="false"  size="small" class="chat-card"
            :class="{ active: chat.id===chatStore.activeChatId }" content-style="padding: 0;">
              <NAvatar  class="avatar"  :size="72" 
                :src="botDicts[chat.botId]?.header"/>
              <div class="chat-card-content">
                <div class="name">{{ botDicts[chat.botId]?.name }}</div>
                <div class="summary">{{ chat.summary ?? '暂时没有聊天记录...'}}</div>
              </div>
            </NCard>
        </NBadge>
    </RouterLink>
    <RouterLink :to="('/find')">
      <NCard  :bordered="false"  size="small" class="more-card" content-style="padding: 0;">
        <div style="height: 100px;margin: 20px;">
          <p style="font: 36px bold;">发现更多</p>
          <p style="font:18px bold;">有趣的AI朋友</p>
          <p style="font:18px bold;">>>>></p>
        </div>
      </NCard>
    </RouterLink>
    </div>
  </template>
</template>

<style scoped>
  #page-root{
    
  }
  .chat-badge{
    width: 100%;
    margin: 2px 0 2px 0;
  }
  .chat-card{
    margin: 0;
    height: 72px;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
  .chat-card.active{
    background-color: azure;
  }
  .chat-card:hover{
    transform: 
      translateY(-5px) 
      scaleY(1.05);
    border-top: 1px solid lightgray;
  }
  .chat-card-content{
    max-width: 240px;
    width: 100%;
    height: 72px;
    display: inline-block;
    padding: 8px;
    vertical-align: top;
    line-height: 28px;
  }

  .chat-card .avatar{
    display: inline-block;
  }

  .chat-card div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-card .name{
    max-width: 70%;
    font:  20px ;
  }
  .chat-card .summary{
    max-width: 100%;
    color: #7a7a7a;
  }

  .more-card {
    margin: 12px 0px;
    width: 100%;
    height: 154px;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    color: white;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.5) url('/image/more-bots-2.jpg');
    background-blend-mode: overlay;
    background-size: cover;
    
  }
</style>