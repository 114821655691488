export class DateTimeUtil {
    /**
     * 格式化日期时间字符串
     * @param datetime 日期时间字符串或 Date 对象
     * @param format 格式化字符串，默认为 'yyyy-MM-dd HH:mm:ss'
     */
    static format(datetime: string | Date, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
      const date = typeof datetime === 'string' ? new Date(datetime) : datetime;
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');
      const second = date.getSeconds().toString().padStart(2, '0');
      return format
        .replace('yyyy', year)
        .replace('MM', month)
        .replace('dd', day)
        .replace('HH', hour)
        .replace('mm', minute)
        .replace('ss', second);
    }
 
    /**
     * 获取指定日期时间的零点时间
     * @param datetime 日期时间字符串或 Date 对象
     */
    static getStartOfDay(datetime: string | Date): Date {
      const date = typeof datetime === 'string' ? new Date(datetime) : datetime;
      date.setHours(0, 0, 0, 0);
      return date;
    }
  
    /**
     * 获取指定日期时间的当天结束时间
     * @param datetime 日期时间字符串或 Date 对象
     */
    static getEndOfDay(datetime: string | Date): Date {
      const date = typeof datetime === 'string' ? new Date(datetime) : datetime;
      date.setHours(23, 59, 59, 999);
      return date;
    }
  
    /**
     * 获取指定日期时间之前的几天的日期时间
     * @param datetime 日期时间字符串或 Date 对象
     * @param days 天数，默认为 1
     */
    static getDaysAgo(datetime: string | Date, days: number = 1): Date {
      const date = typeof datetime === 'string' ? new Date(datetime) : datetime;
      const timestamp = date.getTime() - days * 24 * 60 * 60 * 1000;
      return new Date(timestamp);
    }
  
    /**
     * 获取指定日期时间之后的几天的日期时间
     * @param datetime 日期时间字符串或 Date 对象
     * @param days 天数，默认为 1
     */
    static getTimeAfterDays(days: number , datetime: string | Date = new Date(), ): Date {
      const date = typeof datetime === 'string' ? new Date(datetime) : datetime;
      const timestamp = date.getTime() + days * 24 * 60 * 60 * 1000;
      return new Date(timestamp);
    }


    /**
     * 在当前时间之后添加一段时间
     * @param years 年数
     * @param months 月数
     * @param days 天数
     * @param hours 小时数
     * @param minutes 分钟数
     * @param seconds 秒数
     * @returns 添加指定时间之后的时间对象
     */
    static getTimeAfter(years: number, months: number, days: number, hours: number, minutes: number, seconds: number): Date {
        const now = new Date();
        now.setFullYear(now.getFullYear() + years);
        now.setMonth(now.getMonth() + months);
        now.setDate(now.getDate() + days);
        now.setHours(now.getHours() + hours);
        now.setMinutes(now.getMinutes() + minutes);
        now.setSeconds(now.getSeconds() + seconds);
        return now;
    }

    /**
     * 计算当前时间与输入时间之间的时间差（以毫秒为单位）
     * @param fromTime - 输入时间，可以是 Date 类型或时间日期字符串
     * @returns 时间差（以毫秒为单位）
     */
    static getTimeDiff(fromTime: Date | string): number {
      const fromTimeMs = new Date(fromTime).getTime();
      const currentTime = Date.now();
      const diff = currentTime - fromTimeMs;
      return diff;
    }


  }
  