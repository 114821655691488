export enum ApiStatusCode{
    Success = "Success",
    Unauthorized = "Unauthorized",
    
    Error = "Error",
    Error_Server = "Error_Server",
    Error_Params = "Error_Params",
    Error_Network = "Error_Network",

    Error_InviteCode_NotFound = "Error_InviteCode_NotFound",
    Error_InviteCode_Unavailable = "Error_InviteCode_Unavailable",
    Error_User_NotFound = "Error_User_NotFound",
    Error_User_Forbidden = "Error_User_Forbidden",


}

// export interface ApiError<T>{
//     errorCode: ApiStatusCode
//     message?: string
//     detail?: T
// }

export  class ApiError<T> extends Error {
    errorCode: ApiStatusCode;
    detail?: T;
  
    constructor(errorCode: ApiStatusCode, message?: string, detail?: T) {
      super(message);
      this.name = "ApiError"; 
      this.errorCode = errorCode;
      this.detail = detail;
  
      Object.setPrototypeOf(this, ApiError.prototype);
    }
  }
  