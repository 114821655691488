import type { AxiosError, AxiosProgressEvent, AxiosResponse, GenericAbortSignal } from 'axios'
import request from './axios'
import { useSessionStore } from '@/store'
import { ApiStatusCode, ApiError } from '@/api'

// TO-DO: 这一层的存在没有意义，应重构合并至api层

export interface HttpOption {
  url: string
  data?: any
  method?: string
  headers?: any
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: GenericAbortSignal
  beforeRequest?: () => void
  afterRequest?: () => void
}

export interface ResponseBody<T> {
  status: ApiStatusCode
  data?: T
  message?: string
}

export interface ErrorResponseBody<T> {
  detail?: T
}



function http<T = any>(
  { url, data, method, headers, onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
) {
  afterRequest?.()

  const successHandler = (response: AxiosResponse<ResponseBody<T>>) => {
    if (response.data.status === ApiStatusCode.Success)
      return response.data.data 
    else{
      console.error(`Error:${response.data.message}->${response.data.data}`)
      const error:ApiError<T> = new ApiError<T>(
        response.data.status,
        response.data.message,
        response.data.data
      ) 
      return Promise.reject(error)
    }
  }

  const failHandler = (error: AxiosError<ErrorResponseBody<T>>) => {
    afterRequest?.()
    
    if(error.response){
      const response = error.response
      let newError:ApiError<T>
      switch(response.status){
        case 401:{
          useSessionStore().checkSessionStatus()
          newError = new ApiError<T>(
            ApiStatusCode.Unauthorized,
            "登录失效",
            response.data.detail
          ) 
          break
        }
        case 422:{
          newError = new ApiError<T>(
            ApiStatusCode.Error_Params,
            "参数错误",
            response.data.detail
          )
          break
        }
        case 500:{
          newError = new ApiError<T>(
            ApiStatusCode.Error_Server,
            "服务器内部错误",
            response.data.detail
          )
          break
        }
        default:{
          newError = new ApiError<T>(
            ApiStatusCode.Error,
            "未知错误",
            response.data.detail
          )
        }
      }

      return Promise.reject(newError)
    }
    else{
      const newError:ApiError<string> = new ApiError<string>(
        ApiStatusCode.Error_Network,
        "网络错误",
        error.message
      ) 
      return Promise.reject(newError)
    }
  }

  beforeRequest?.() 

  method = method || 'GET'

  const params = Object.assign(typeof data === 'function' ? data() : data ?? {}, {})

  return method === 'GET'
    ? request.get(url, { params, signal, onDownloadProgress}).then(successHandler, failHandler)
    : request.post(url, params, { headers, signal, onDownloadProgress}).then(successHandler, failHandler)
}

export function get<T = any>(
  { url, data, method = 'GET', onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
): Promise<T|undefined> {
  return http<T>({
    url,
    method,
    data,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
  })
}

export function post<T = any>(
  { url, data, method = 'POST', headers, onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
): Promise<T|undefined> {
  return http<T>({
    url,
    method,
    data,
    headers,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
  })
}

export default post

export interface SseOption {
  url: string
  params?: Map<string, string>
  onOpen?: (e: Event) => any
  onError?: (e: Event) => any
  onMessage?: (e: MessageEvent) => any
  onEvents?: Map<string, (e: MessageEvent) => any>
}

export function sse(
  { url, params, onOpen, onError, onMessage, onEvents 
  }: SseOption): EventSource {
  try {

    let queryString = ''
    if (params && params.size > 0) {
      const query: string[] = []
      params.forEach((v, k) => {
        query.push(`${k}=${encodeURIComponent(v)}`)
      })
      queryString = `?${query.join('&')}`
    }

    const evtSource = new EventSource(
      url + queryString ,
      { withCredentials: true })

    if(onOpen)
      evtSource.onopen = onOpen

    if(onError)
      evtSource.onerror = onerror

    if (onMessage)
      evtSource.onmessage = onMessage

    if (onEvents && onEvents?.size > 0) {
      onEvents.forEach((handler, event) => {
        evtSource.addEventListener(event, handler)
      })
    }

    return evtSource
  }
  catch (e) {
    console.error(e)
    throw e
  }
}
