import { ss } from '@/utils/storage'
import { Models } from '@/typings'
import { useFriendStore } from '../friend'
import { useSessionStore } from '../session/session'

const LOCAL_NAME = 'chat-store'

export function defaultState(): Models.ChatState {
    let chats: Models.Chat[] = []
    const bots: Models.Bot[] = useFriendStore().bots
    let user = useSessionStore().user

    if(bots.length>0 && user ) {
        bots.forEach(bot => {
            if(bot.isFav){
                const chat = createDefaultChat(bot)
                chat && chats.push(chat)
            }
        })
    }
    else{
        console.log('load chatStore state fail: ' + user + bots)
    }

    return {
        chats: chats,
        activeChatId: chats[0]?.id,
        showSider: true
    }
}

export function getLocalState(): Models.ChatState {
  const localState = ss.get(LOCAL_NAME)
  return { ...defaultState(), ...localState }
}

export function setLocalState(state: Models.ChatState) {
  ss.set(LOCAL_NAME, state)
}

export function createDefaultChat(bot:Models.Bot){
    const userId = useSessionStore().user?.id
    if(!userId) return null

    const chat = {
        id: bot.id,
        userId: userId,
        botId: bot.id,
        isChatMode: true,
        busyMode:{
            isBusy : false
        },
        conversations: [] as Models.Conversation[],
        newMsgCount: 0,
        summary: '',
        createTime: new Date(),
        updateTime: new Date(),
    }

    if(bot.greeting){
        const exampleConv = {
            id: 'example',
            chatId: chat.id,
            userId: userId,
            botId: bot.id,
            messages: [
                {
                    id: 'example',
                    parentMsgId: '',
                    convId: 'example',
                    chatId: chat.id,
                    clientId: 'example',
                    userId: userId,
                    msgType: Models.MessageType.AI,
                    status: Models.MessageStatus.Done,
                    content: bot.greeting,
                    rawContent: '',
                    createTime: new Date(),
                    updateTime: new Date(),
                } as Models.Message
            ] as Models.Message[],
            summary: bot.greeting,
            createTime: new Date(),
            updateTime: new Date(),
        }
        chat.conversations.push(exampleConv)
        chat.newMsgCount = 1
        if(exampleConv.summary){
            chat.summary = exampleConv.summary
        }
    }
    return chat as Models.Chat
}