import { defineStore } from 'pinia'
import { getLocalState, setLocalState, createDefaultChat,
    defaultState as getDefaultState } from './helper'
// import { useRoute } from 'vue-router'
import { Models } from '@/typings'
// import { v4 as uuid4 } from 'uuid'
import { useFriendStore, useSessionStore } from '@/store'
// import { checkboxRtl } from 'naive-ui/es/checkbox/styles'
import { isNumber } from '@/utils/is'
// import { ReturnUpBackOutline } from '@vicons/ionicons5'

export const useChatStore = defineStore('chat-store', {
    state: (): Models.ChatState => {
        return getLocalState()
    },

    getters: {
        sortedChats(): Models.Chat[] {
          this.chats.sort((a, b) => new Date(b.updateTime).getTime()  - new Date(a.updateTime).getTime() )
          return this.chats
        },
        activeChat(): Models.Chat | undefined {
            return this.chats.find(chat => chat.id===this.activeChatId)
        },
        activeConv(): Models.Conversation | undefined {
            if(this.activeChat){
                const convs = this.activeChat.conversations
                return convs[ convs.length - 1 ]
            }
        },
        activeMessage(): Models.Message | undefined {
            if(this.activeConv) {
                if(this.activeChat?.isChatMode){
                    const messages = this.activeConv.messages
                    return messages[ messages.length - 1 ]
                }
            }
        },
        getChatById(){
            return (chatId:string) => {
                return this.chats.find( item => item.id == chatId)
            }
        },
        getLastMessageInConv(){
            return (conv : Models.Conversation) =>{
                if(conv.messages.length > 0){
                    return conv.messages[conv.messages.length - 1]
                }
            }
        },
        getLastMessageInChat(){
            return (chat: Models.Chat) => {
                if( chat.conversations.length > 0){
                    const lastConv = chat.conversations[chat.conversations.length -1]
                    return this.getLastMessageInConv(lastConv)
                }
            }
        }
    },

    actions: {
        hasChat(chatId: string){
            return this.chats.some(chat => chat.id ===chatId)
        },

        addChat(chatId: string) {
            const chat = this.chats.find(chat => chat.id===chatId)
            const bot = useFriendStore().bots.find( bot => bot.id===chatId)
            if(chat){
                this.changeActiveChat(chatId)
                return chat
            }
            else if(bot){
                const newChat = createDefaultChat(bot)
                if(newChat){
                    this.chats.push(newChat)
                    this.changeActiveChat(newChat.id)
                    return newChat
                }
                return null
            }
            return null
        },

        changeActiveChat(chatId: string){
            console.log('changeActiveChat', chatId)
            if(this.chats.find(chat => chat.id===chatId)){
                this.activeChatId = chatId
                if(this.activeChat){
                  this.activeChat.newMsgCount = 0
                }
                this.save2Local()
                return this.activeChat
            }
            else {
                return null
            }
        },

        clearChat(chat: Models.Chat){
            chat.conversations = [] as Models.Conversation[]
            this.save2Local()
        },

        removeChat(chat: Models.Chat){
            let index = this.chats.indexOf(chat)
            if(index != -1){
                this.chats.splice( index, 1 )
                if(this.activeChatId === chat.id){
                    if(this.chats.length>0){
                        index  = index % this.chats.length
                        this.activeChatId = this.chats[index].id
                    }
                    else{
                        this.activeChatId = null
                    }
                }
                this.save2Local()
            }
        },

        findChatByConvId(convId: string){
            return this.chats.find(chat => chat.conversations.find(conv => conv.id === convId))
        },

        addConversation(conv: Models.Conversation) {
            if(this.activeChat){
                this.activeChat.conversations.push(conv)
            }
        },

        addMessage(message : Models.Message) {

        },

        removeMessage(message: Models.Message) {
            const chat = this.activeChat
            if(chat){
              const conv = chat.conversations.find(item => item.id === message.convId )
              if(conv){
                const index = conv.messages.indexOf(message)
                if(index != -1){
                  conv.messages.splice(index, 1)
                  if(conv.messages.length==0){
                    chat.conversations.splice(chat.conversations.indexOf(conv), 1)
                  }
                  this.save2Local()
                }
              }
            }
        },

        addMessageFromServer(message: Models.Message) {
            let chat = this.$state.chats.find(item => item.id == message.chatId)
            let conv = chat?.conversations.find(item => item.id == message.convId)
            if( chat && conv){
                let index = conv.messages.findIndex( item => item.id == message.id )
                if(index == -1){
                    conv.messages.push(message)
                }
                else{
                    conv.messages[index] = message
                }  

                chat.summary = conv.summary = message.content.slice(0,40)                    
                chat.updateTime = conv.updateTime = new Date()
                chat.newMsgCount    = (chat.id !== this.activeChatId && isNumber(chat.newMsgCount)) 
                                    ? chat.newMsgCount + 1 : 0
                chat.busyMode = {isBusy: false}
                this.save2Local()
            }
        },

        addTokenFromServer(convId:string , msgId:string, token:string){ 
            const user = useSessionStore().user
            if(!user) return

            let chat = this.findChatByConvId(convId)
            let conv = chat?.conversations.find( item => item.id == convId)
            let message = conv?.messages.find( item => item.id == msgId )

            if( chat && conv && message){
                if( message.status === Models.MessageStatus.New
                    || message.status === Models.MessageStatus.Pendding){
                    
                    message.status === Models.MessageStatus.Pendding
                    message.content  += token
                    // msg.updateTime = new Date() 
                    chat.busyMode = {isBusy: true, type:'Recieving', info:'信息接收中...'}
                }
            }

        },

        syncUpBotDbWithFriendStore(){
            if(this.chats.length === 0 ){
                let state = getDefaultState()
                state.chats.forEach(item => this.chats.push(item)) 
                this.activeChatId = state.activeChatId
                this.showSider = state.showSider
                this.save2Local()
            }
        },

        save2Local(){
            setLocalState(this.$state)
            console.log("save chatStore")
        }

    }

})